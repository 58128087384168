import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

import logIn from "./user/logIn";
import user from "./user/user";
import profile from "./user/profile";
import menu from "./menu/menu";
import state from "./common/state";
import city from "./common/city";
import toDoCategory from "./common/toDoCategory";
import toDo from "./toDo/toDo";
import schedule from "./schedule/schedule";
import service from "./service/service";
import customer from "./customer/customer";
import addressCategory from "./common/addressCategory";
import contactCategory from "./common/contactCategory";
import product from "./product/product";
import productCategory from "./product/productCategory";
import productSubCategory from "./product/productSubCategory";
import template from "./sales/template";
import unity from "./common/unity";
import attachment from "./attachment/attachment";
import complexityLevel from "./common/complexityLevel";
import contractorCategory from "./common/contractorCategory";
import contractor from "./common/contractor";
import permit from "./common/permit";
import permitCounty from "./common/permitCounty";
import actions from "./actions/actions";
import customerOrigin from "./common/customerOrigin";
import dashboard from "./dashboard/dashboard";
import workOrder from "./workOrder/workOrder";
import inspection from "./inspection/inspection";
import promotion from "./promotion/promotion";
import consumerResp from "./consumerResp/consumerResp";
import module from "./common/module";
import serviceCalls from "./serviceCalls/serviceCalls";
import reasonUnclosedDeal from "./common/reasonUnclosedDeal";
import colorSelectionMaterialType from "./common/colorSelectionMaterialType";
import colorSelectionProduct from "./common/colorSelectionProduct";

export default new Vuex.Store({
    modules: {
        logInModule: logIn,
        userModule: user,
        profileModule: profile,
        menuModule: menu,
        stateModule: state,
        cityModule: city,
        toDoCategoryModule: toDoCategory,
        toDoModule: toDo,
        scheduleModule: schedule,
        serviceModule: service,
        customerModule: customer,
        addressCategoryModule: addressCategory,
        contactCategoryModule: contactCategory,
        productModule: product,
        productCategoryModule: productCategory,
        productSubCategoryModule: productSubCategory,
        templateModule: template,
        unityModule: unity,
        attachmentModule: attachment,
        complexityLevelModule: complexityLevel,
        contractorCategoryModule: contractorCategory,
        contractorModule: contractor,
        permitModule: permit,
        permitCountyModule: permitCounty,
        actionsModule: actions,
        customerOriginModule: customerOrigin,
        dashboardModule: dashboard,
        workOrderModule: workOrder,
        inspectionModule: inspection,
        promotionModule: promotion,
        consumerRespModule: consumerResp,
        moduleModule: module,
        serviceCallsModule: serviceCalls,
        reasonUnclosedDealModule: reasonUnclosedDeal,
        colorSelectionMaterialTypeModule: colorSelectionMaterialType,
        colorSelectionProductModule: colorSelectionProduct,
    },

    state: {
        loader: false,
        modalToDo: false,
        qtdeToDo: 0,
        menuSide: true
    },
    
    mutations: {
        Loader(state, payload) {
            state.loader = payload;
        },

        ModalToDo(state, { payload, qtdeToDo }) {
            
            state.modalToDo = payload;
            state.qtdeToDo = qtdeToDo;
        },

        OpenMenuSide(state, payload) {
            state.menuSide = payload
        }
    }
})