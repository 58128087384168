
import Vue from 'vue'

const getDefaultState = () => {
    return {
        workOrder: {
          id: 0,
        },
    }
};

const state = getDefaultState();

const actions = {

    resetState({ commit }) {
        commit('resetState');
    },

    saveState({ commit }, payload) {
        commit('saveState', payload);
    },

    async GetById( { commit }, idWorkOrder) {

        try {
            const result = await Vue.prototype.$vanKirkApi.get(`workOrder/${idWorkOrder}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 200) {

                let workOrderResponse = result.data;

                commit('saveState', workOrderResponse);

                return workOrderResponse;
            }
            else {
                return false
            }
        }
        catch(ex) {
            console.log("Error to get work order", ex);
            return false;
        }
    },

    async ListNotification() {

        try {

            const result = await Vue.prototype.$vanKirkApi.get("workOrder/list-notification",
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                return result.data.result;
            }
            else {
                return 0;
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error to list notification work order: [${ex}]`
            };
        }
    },

    async List() {

        try {

            const result = await Vue.prototype.$vanKirkApi.get("workOrder/list",
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                let listWorkOrder = result.data.result;
                return listWorkOrder;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error to list work order: [${ex}]`
            };
        }
    },

    async ListType() {

        try {

            const result = await Vue.prototype.$vanKirkApi.get("workOrder/list-type-work-order",
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                let listWorkOrderType = result.data.result;
                return listWorkOrderType;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error to list type work order: [${ex}]`
            };
        }
    },

    async CreateUpdate(_, workOrderRequest) {

        try {

            const result = await Vue.prototype.$vanKirkApi.post(`workOrder/create-update`, workOrderRequest,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 200) {

                let msg = "Register updated with success!";

                if (workOrderRequest.id === 0) {
                    msg = "Record created successfully";
                }

                return {
                    success: true,
                    workOrderResponse: result.data.result,
                    message: msg
                };
            }
            else {
                return {
                    success: false,
                    message: "Error to create/update register!"
                };
            }
        }
        catch(error) {

            if (error.response && error.response.status === 400) {
                return {
                    success: false,
                    message: `Error to create/update register: [${error.response.data}]`
                };
            }
            else {

                return {
                    success: false,
                    message: `Error to create/update register: [${error}]`
                };
            }
        }
    },

    async UpdateStatus(_, updateStatusRequest) {

        try {
            const result = await Vue.prototype.$vanKirkApi.put(`workOrder/update-status/${updateStatusRequest.id}`, updateStatusRequest,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 204) {
                return {
                    success: true,
                    message: "Status updated with successfully"
                };
            }
            else {
                return {
                    success: false,
                    message: "Error updating the status!"
                };
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error updating the status: [${ex}]`
            };
        }
    },

    async ListStatus() {

        try {
            const result = await Vue.prototype.$vanKirkApi.get(`workOrder/list-status`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                let listStatus = result.data.result;

                return listStatus;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error to list status: [${ex}]`
            };
        }
    },

    async CheckExistsLinkedItems( _, idServiceProject) {

        try {
            const result = await Vue.prototype.$vanKirkApi.get(`workOrder/check-exists-linked-items/${idServiceProject}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 200) {

                return result.data.result.idWorkOrder;
            }
            else {
                return false
            }
        }
        catch(ex) {
            console.log("Error to check linked items", ex);
            return false;
        }
    },
}

const mutations = {

    saveState: (state, newValue) => {
        Object.assign(state.workOrder, newValue);
    },

    resetState: (state) => {
        Object.assign(state, getDefaultState());
    }
}

const getters = {

    searchState: (state) => {
        return state.workOrder;
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
