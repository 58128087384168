import Vue from 'vue'
import axios from 'axios'
import { showToast, hideLoading } from '@/utilities/Utils'

const getToken = async (config) => {

    if (Vue.prototype.getRoot !== undefined) {

        const token = localStorage.getItem("token");

        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
    }

    return config;
}

const interceptorMessages = (error) => {

    hideLoading();

    if (error && error.message === 'Network Error') {
        showToast("error", "Error!", "Communication failure with the server. Try again!");
    } 
    else {

        const { response: { status } } = error

        if (status != undefined) {
            
            if (status === 500) {
                showToast("error", "Error!", "An unexpected error has occurred!");
            } 
            
            else if (status === 401 && window.location.pathname !== "/login") {
                // showToast("warning", "Warning!", "Unauthorized access!");
                localStorage.clear();
                Vue.prototype.getRouter.push({ name:"login" });
            } 
            
            else if (status === 404) {
                showToast("error", "Error!", "Action not found!");
            } 
            
            else if (status === 403) {
                showToast("warning", "Warning!", "Access denied!");
                Vue.prototype.getRouter.push({name:"AccessDenied"});
            }
        } 
        else {
            showToast("error", "Error!", "An unexpected error has occurred! Try later.");
        }
    }

};

Vue.use({
    install(Vue) {

        /* CONFIGURAÇÃO DA API - VANKIRK */
        Vue.prototype.$vanKirkApi = axios.create({
            baseURL: process.env.VUE_APP_URL_API
        });

        Vue.prototype.$vanKirkApi.interceptors.request.use(async config => {
            return await getToken(config);
        },
        error => {
            return Promise.reject(error);
        });

        Vue.prototype.$vanKirkApi.interceptors.response.use((response) => {
            return response;
        }, 
        error => {
            interceptorMessages(error);
            return Promise.reject(error);
        });
    }
})
