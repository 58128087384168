<template>
    <v-app>
        <v-main>
            <template v-if="$route.meta.requiresAuth">
                <Menu />
                <Toolbar />
                <v-container fluid>
                    <transition name="slide-x-transition" mode="out-in">
						<router-view :key="$route.name"></router-view>
                    </transition>
                </v-container>
            </template>
            <template v-else>
                <transition name="slide-x-transition" mode="out-in">
					<router-view :key="$route.name"></router-view>
                </transition>
            </template>
        </v-main>

        <Loader />
        <ModalToDo />
    </v-app>
</template>

<script>

	import Vue from 'vue';
	import Loader from '@/components/Layout/Loader';
	import ModalToDo from '@/components/Layout/ModalToDo';
	import Menu from "@/views/Main/Menu";
	import Toolbar from "@/views/Main/Toolbar";

	export default {
		name: 'App',

		components: {
			Loader,
			ModalToDo,
			Menu,
			Toolbar
		},

		created() {
			Vue.prototype.getApp = this;
			Vue.prototype.getToast = this.$toast;
			Vue.prototype.getDialog = this.$dialog;
			Vue.prototype.getRouter = this.$router;
			Vue.prototype.getRoot = this.$root;
		},
	};
</script>

<style>

	.copyright {
		direction: rtl;
		font-size: small;
		background-color: var(--color__main);
		color:white;
		height: 30px;
		line-height: 30px;
		margin-bottom: 0px !important;
		text-align: center;
	}

</style>