import { format } from '@/directives/v-formatMoney/utils' //busca da diretiva v-formatMoney
import Vue from 'vue'
import store from '@/store/store'

function showLoading() {
	store.commit('Loader', true);
}

function hideLoading() {
	store.commit('Loader', false);
}

function openSideMenu(open) {
	store.commit('OpenMenuSide', open);
}

function showModalToDo(qtdeToDo) {
	store.commit('ModalToDo', { payload: true, qtdeToDo: qtdeToDo } );
}

function hideModalToDo(qtdeToDo) {
	store.commit('ModalToDo', { payload: false, qtdeToDo: qtdeToDo });
}

function formaterDecimalBR(value, precision = 2) {
	if (value) {

		var defaults = {
			prefix: '',
			suffix: '',
			thousands: ',',
			decimal: '.',
			precision: precision
		};

		let valorArray = value.toString().split(".");
			
		if (valorArray[1] != undefined) {

			const qtdDecimal = valorArray[1].length;

			if (qtdDecimal > 2) {
				defaults.precision = qtdDecimal;
			} else if (qtdDecimal < 2) {
				value = value.toFixed(2);// caso retornar da api uma case decimal, quando for valor redondo, EX: 100.6, forçar duas casas decimais
			}

		}
		else {
			value = value.toFixed(2);
		}
 
		return format(value, defaults);

	}

	if (value == null || value == 0) { return "0,00"; }
}

function formaterDecimalBRServer(value) {
	if(value == undefined)
		return value;

	value = value.toString().replaceAll('$ ', '').replaceAll('$', '').replaceAll('% ', '').replaceAll('%', '').replaceAll(',', '');

	return value;
}

function showToast(state, title, content) {

	var timer = 8000;
	var foreColor = "#FFFFFF";
	var titleFontColor = "#FFFFFF";
	var iconFontColor = "#FFFFFF";
	var messageFontColor = "#FFFFFF";
	var fxIn = "bounceInLeft";
	var fxOut = "fadeOutRight"

	switch (state) {

		case "info":
			Vue.prototype.toast.info(content, title, {transitionIn:fxIn, transitionOut: fxOut, progressBarColor: foreColor, color: foreColor, backgroundColor: 'rgba(30, 136, 229,1)', titleColor: titleFontColor, icon: 'mdi mdi-information-variant', iconColor: iconFontColor, messageColor: messageFontColor, timeout: timer });
			break;

		case "warning":
			Vue.prototype.toast.warning(content, title, {transitionIn:fxIn, transitionOut: fxOut, progressBarColor: foreColor,  color: foreColor, backgroundColor: 'rgba(255, 152, 0,1)', titleColor: titleFontColor, icon: 'mdi mdi-comment-alert-outline', iconColor: iconFontColor, messageColor: messageFontColor, timeout: timer });
			break;

		case "error":
			Vue.prototype.toast.error(content, title, {transitionIn:fxIn, transitionOut: fxOut, progressBarColor: foreColor, color: foreColor, backgroundColor: 'rgba(255, 0, 69,1)', titleColor: titleFontColor, icon: 'mdi mdi-alert-decagram-outline', iconColor: iconFontColor, messageColor: messageFontColor, timeout: timer })
			break;

		case "success":
			Vue.prototype.toast.success(content, title, {transitionIn:fxIn, transitionOut: fxOut, progressBarColor: foreColor, color: foreColor, backgroundColor: 'rgba(76, 175, 80,1)', titleColor: titleFontColor, icon: 'mdi mdi-check', iconColor: iconFontColor, messageColor: messageFontColor, timeout: timer });
			break;
	}
}

function showDialog(text, func, params) {
	const options = {
		html: true, // set to true if your message contains HTML tags. eg: "Delete <b>Foo</b> ?"
		loader: false, // set to true if you want the dailog to show a loader after click on "proceed"
		reverse: false, // switch the button positions (left to right, and vise versa)
		okText: 'OK',
		cancelText: 'Cancelar',
		animation: 'bounce', // Available: "zoom", "bounce", "fade"
	}

	Vue.prototype.$dialog.confirm(text, options).then(() => func(params))
}

function filterValuePart(arr, part, columnsToFilter) {
    return arr.filter(function (obj) {
        return Object.keys(obj)
            .some(function (k) {
                if (obj[k] !== null && columnsToFilter.includes(k))
                    return obj[k].toString().toLowerCase().indexOf(part.toString().toLowerCase()) !== -1;
            });
    });
}

function orderBy( a, b, type, field ) {

	if (type == 0) { //asc
		return a[field] < b[field] ? -1 : 1;
	}
	else {
		return a[field] > b[field] ? -1 : 1;
	}
}

function downloadFile(url, id, contentType, fileName) {

	if (id != 0) {

		showLoading();

		Vue.prototype.$vanKirkApi.get(`${url}/${id}`, {
				responseType: "blob",
			})
			.then((response) => {

				if (contentType == null || contentType == undefined || contentType == '' ) {

					const blob = new Blob([response.data], {
						type: "application/octet-stream",
					});

					const url = window.URL.createObjectURL(blob);
					let a = document.createElement("a");

					document.body.appendChild(a);
					a.style = "display: none";
					a.href = url;
					a.download = fileName;
					a.click();
					window.URL.revokeObjectURL(url);
				}
				else {

					const blob = new Blob([response.data], {
						type: contentType,
					});

					const url = window.URL.createObjectURL(blob);
					window.open(url);
				}

				hideLoading();
			})
			.catch(error => {
				
				let errorMessage = "";
				if (error.response && error.response.status === 400) {
					errorMessage = `Error to download file: [${error.response.data}]`;
				}
				else {
					errorMessage = `Error to download file: [${error}]`;
				}
				
				showToast("error", "Erro!", errorMessage);
				hideLoading();
			}
		);
	}
}

function exportFile(url, request, fileName) {

	if (url != null && url != undefined && url != '') {

		showLoading();

		Vue.prototype.$vanKirkApi.post(`${url}`, request, {
				responseType: "blob",
			})
			.then((response) => {

				const blob = new Blob([response.data], {
					type: "application/octet-stream",
				});

				const url = window.URL.createObjectURL(blob);
				let a = document.createElement("a");

				document.body.appendChild(a);
				a.style = "display: none";
				a.href = url;
				a.download = fileName;
				a.click();
				window.URL.revokeObjectURL(url);

				hideLoading();
			})
			.catch(error => {
				
				let errorMessage = "";
				if (error.response && error.response.status === 400) {
					errorMessage = `Error to download excel file: [${error.response.data}]`;
				}
				else {
					errorMessage = `Error to download excel file: [${error}]`;
				}
				
				showToast("error", "Erro!", errorMessage);
				hideLoading();
			}
		);
	}
}

function syncScroll() {
	const box1 = document.getElementById('divHeaders');
	const box2 = document.getElementById('divContents');

	box1.scrollLeft = box2.scrollLeft;
}

function resizePreviewImage(filesPhotos, listPhotos) {

	if (filesPhotos) {

		filesPhotos.forEach(itemPhoto => {

			if (itemPhoto) {

				const type = itemPhoto.type;

				if (type.toString().toLowerCase().includes('pdf')) {

					let fileName = itemPhoto.name;

					if (itemPhoto.name && itemPhoto.name.length > 12) {
						fileName = itemPhoto.name.toString().substring(0, 12) + "..."
					}

					let item = {
						id: 0,
						file: itemPhoto,
						fileName: fileName,
						type: 'pdf',
						deleted: false,
						src: null
					}

					listPhotos.push(item);

				}
				else {

					var canvas = document.getElementById("canvas");
					var ctx = canvas.getContext("2d");
					var maxW = 400;
					var maxH = 400;

					var img = new Image();

					new Promise(resolve => {

						img.onload = function() {
							var iw = img.width;
							var ih = img.height;
							var scale = Math.min(maxW / iw, maxH / ih);
							var iwScaled = iw * scale;
							var ihScaled = ih * scale;
							canvas.width = iwScaled;
							canvas.height = ihScaled;
							ctx.drawImage(img, 0, 0, iwScaled, ihScaled);
							const _photo = canvas.toDataURL(type, 1.0);
							resolve(_photo);
						};

					}).then(result => {

						let fileName = itemPhoto.name;

						if (itemPhoto.name && itemPhoto.name.length > 12) {
							fileName = itemPhoto.name.toString().substring(0, 12) + "..."
						}

						let item = {
							id: 0,
							file: itemPhoto,
							fileName: fileName,
							type: type,
							deleted: false,
							src: result
						}

						listPhotos.push(item);
					});

					img.src = URL.createObjectURL(itemPhoto);
				}
			}	
		});
	}
}

function getStatusColor (status) {

	let color = "";

	switch (status) {

		case 0: color = 'var(--color__silver)'; break;
		case 1: color = 'var(--color__red)'; break;
		case 2: color = 'var(--color__main)'; break;
		case 3: color = 'var(--color__status_pending)'; break;
		case 4: color = 'var(--color__completed)'; break;
		case 5: color = 'var(--color__completed)'; break;
		default: color = 'var(--color__main)'; break;
	}

	return color
}

export {
	formaterDecimalBR, 
	formaterDecimalBRServer, 
	showToast, 
	showDialog, 
	showLoading, 
	hideLoading, 
	openSideMenu, 
	showModalToDo, 
	hideModalToDo,
	filterValuePart,
	orderBy,
	downloadFile,
	exportFile,
	syncScroll,
	resizePreviewImage,
	getStatusColor
} 