
import Vue from 'vue'

const getDefaultState = () => {
    return {
        service: {
            id: 0,
            dateRegister: 0,
            idUserRegister: 0,
            isCustomer: 0,
            name: "",
            address: "",
            estimatedValueZillow: 0,
            phone: "",
            email: "",
            notes: "",
            status: "",
            idUserResponsible: ""
        }
    }
};

const state = getDefaultState();

const actions = {

    resetState({ commit }) {
        commit('resetState');
    },

    saveState({ commit }, payload) {
        commit('saveState', payload);
    },

    async List() {

        try {
            const result = await Vue.prototype.$vanKirkApi.get("service-calls/list",
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                let listService = result.data.result;

                return listService;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error to list service calls: [${ex}]`
            };
        }
    },

    async GetById(_, id) {

        try {
            const result = await Vue.prototype.$vanKirkApi.get(`service-calls/${id}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 200) {
                return result.data;
            }
            else {
                return {
                    success: false,
                    result: `Error to get service call`
                };
            }
        }
        catch(ex) {
            return {
                success: false,
                result: `Error to get service call: [${ex}]`
            };
        }
    },

    async CreateUpdate(_, serviceRequest) {

        try {

            const result = await Vue.prototype.$vanKirkApi.post(`service-calls/create-update`, serviceRequest,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 200) {

                let msg = "Register updated with successfully";

                if (serviceRequest.id === 0) {
                    msg = "Record created successfully";
                }

                return {
                    success: true,
                    serviceCallResponse: result.data.result,
                    message: msg
                };
            }
            else {
                return {
                    success: false,
                    message: "Error to create/update register!"
                };
            }
        }
        catch(error) {

            if (error.response && error.response.status === 400) {
                return {
                    success: false,
                    message: `Error to create/update register: [${error.response.data}]`
                };
            }
            else {

                return {
                    success: false,
                    message: `Error to create/update register: [${error}]`
                };
            }
        }
    },

    async UpdateStatus(_, updateStatusRequest) {

        try {
            const result = await Vue.prototype.$vanKirkApi.put(`service-calls/update-status/${updateStatusRequest.id}`, updateStatusRequest,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 204) {
                return {
                    success: true,
                    message: "Status updated with success!"
                };
            }
            else {
                return {
                    success: false,
                    message: "Error updating the status!"
                };
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error updating the status: [${ex}]`
            };
        }
    },

    async ListStatus() {

        try {
            const result = await Vue.prototype.$vanKirkApi.get(`service-calls/list-status`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                let listStatus = result.data.result;

                return listStatus;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error to list job status: [${ex}]`
            };
        }
    },

    async ListHistory(_, idServiceCall) {

        try {
            const result = await Vue.prototype.$vanKirkApi.get(`service-calls/list-history/${idServiceCall}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                let listHistory = result.data.result;

                return listHistory;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error to list history: [${ex}]`
            };
        }
    },

    async ListServiceType() {

        try {
            const result = await Vue.prototype.$vanKirkApi.get(`service-calls/list-service-type`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                let listServiceType = result.data.result;

                return listServiceType;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error to list service types: [${ex}]`
            };
        }
    },
}

const mutations = {

    saveState: (state, newValue) => {
        Object.assign(state.service, newValue);
    },

    resetState: (state) => {
        Object.assign(state, getDefaultState());
    }
}

const getters = {

    searchState: (state) => {
        return state.service;
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
