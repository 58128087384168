
import Vue from 'vue'

const getDefaultState = () => {
    return {
        customer: {
            id: 0,
            name: "",
            listAddressRequest: [],
            listContactRequest: [],
            listNoteRequest: []
        }
    }
};

const state = getDefaultState();

const actions = {

    resetState({ commit }) {
        commit('resetState');
    },

    saveState({ commit }, payload) {
        commit('saveState', payload);
    },

    async List() {

        try {
            const result = await Vue.prototype.$vanKirkApi.get("customer/list",
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                let listCustomer = result.data.result;

                return listCustomer;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error to list customer: [${ex}]`
            };
        }
    },

    async ListByServiceUserLogged() {

        try {
            const result = await Vue.prototype.$vanKirkApi.get("customer/list-by-service-user-logged",
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                let listCustomer = result.data.result;

                return listCustomer;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error to list customer: [${ex}]`
            };
        }
    },

    async ListAddress(_, id) {

        try {
            const result = await Vue.prototype.$vanKirkApi.get(`customer/list-address/${id}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                let listAddress = result.data.result;

                return listAddress;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error to list customer address: [${ex}]`
            };
        }
    },

    async ListContacts(_, id) {

        try {
            const result = await Vue.prototype.$vanKirkApi.get(`customer/list-contacts/${id}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                let ListContacts = result.data.result;

                return ListContacts;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error to list customer contacts: [${ex}]`
            };
        }
    },

    async GetById(_, id) {

        try {
            const result = await Vue.prototype.$vanKirkApi.get(`customer/${id}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 200) {
                return result.data;
            }
            else {
                return {
                    success: false,
                    result: `Error to get customer`
                };
            }
        }
        catch(ex) {
            return {
                success: false,
                result: `Error to get customer: [${ex}]`
            };
        }
    },

    async CreateUpdate(_, customerRequest) {

        try {

            const result = await Vue.prototype.$vanKirkApi.post(`customer/create-update`, customerRequest,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 204) {

                let msg = "Register updated with success!";

                if (customerRequest.id === 0) {
                    msg = "Record created successfully";
                }

                return {
                    success: true,
                    message: msg
                };
            }
            else {
                return {
                    success: false,
                    message: "Error to create/update register!"
                };
            }
        }
        catch(error) {

            if (error.response && error.response.status === 400) {
                return {
                    success: false,
                    message: `Error to create/update register: [${error.response.data}]`
                };
            }
            else {

                return {
                    success: false,
                    message: `Error to create/update register: [${error}]`
                };
            }
        }
    },

    async UpdateStatus(_, { id, statusId }) {

        try {
            const result = await Vue.prototype.$vanKirkApi.put(`customer/update-status/${id}/${statusId}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 204) {
                return {
                    success: true,
                    message: "Status updated with success!"
                };
            }
            else {
                return {
                    success: false,
                    message: "Error updating the status!"
                };
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error updating the status: [${ex}]`
            };
        }
    },
}

const mutations = {

    saveState: (state, newValue) => {
        Object.assign(state.customer, newValue);
    },

    resetState: (state) => {
        Object.assign(state, getDefaultState());
    }
}

const getters = {

    searchState: (state) => {
        return state.customer;
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
