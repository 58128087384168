import Vue from 'vue'

const actions = {

    async ListAllSchedule(_, scheduleFilterRequest) {

        try {
            const result = await Vue.prototype.$vanKirkApi.post("schedule/listAllSchedule", scheduleFilterRequest, 
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                let listAllSchedule = result.data.result;

                return listAllSchedule;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error to list all schedule: [${ex}]`
            };
        }
    },

    async DateBlockedList() {

        try {
            const result = await Vue.prototype.$vanKirkApi.get("schedule/dateBlocked/list",
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                let listScheduleBlocked = result.data.result;

                return listScheduleBlocked;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error to list schedule blocked: [${ex}]`
            };
        }
    },

    async DateBlockedGetById(_, id) {

        try {
            const result = await Vue.prototype.$vanKirkApi.get(`schedule/dateBlocked/${id}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 200) {
                return result.data;
            }
            else {
                return {
                    success: false,
                    result: `Error to get schedule blocked`
                };
            }
        }
        catch(ex) {
            return {
                success: false,
                result: `Error to get schedule blocked: [${ex}]`
            };
        }
    },

    async DateBlockedCreateUpdate(_, scheduleBlockRequest) {

        try {
            const result = await Vue.prototype.$vanKirkApi.post(`schedule/dateBlocked/create-update`, scheduleBlockRequest,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 204) {

                let msg = "Register updated with success!";

                if (scheduleBlockRequest.id === 0) {
                    msg = "Record created successfully";
                }

                return {
                    success: true,
                    message: msg
                };
            }
            else {
                return {
                    success: false,
                    message: "Error to create/update register!"
                };
            }
        }
        catch(error) {

            if (error.response && error.response.status === 400) {
                return {
                    success: false,
                    message: `Error to create/update register: [${error.response.data}]`
                };
            }
            else {

                return {
                    success: false,
                    message: `Error to create/update register: [${error}]`
                };
            }
        }
    },

    async DateBlockedDelete(_, id) {

        try {
            const result = await Vue.prototype.$vanKirkApi.delete(`schedule/dateBlocked/${id}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 200) {

                return {
                    success: true,
                    message: "Register deleted with success!"
                };
            }
        }
        catch(error) {

            if (error.response && error.response.status === 400) {
                return {
                    success: false,
                    message: `Error to delete the register: [${error.response.data}]`
                };
            }
            else {

                return {
                    success: false,
                    message: `Error to delete the register: [${error}]`
                };
            }
        }
    },

    async DateMeetingList() {

        try {
            const result = await Vue.prototype.$vanKirkApi.get("schedule/date-meeting/list",
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                let listScheduleMeeting = result.data.result;

                return listScheduleMeeting;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error to list schedule meeting: [${ex}]`
            };
        }
    },

    async DateMeetingGetById(_, id) {

        try {
            const result = await Vue.prototype.$vanKirkApi.get(`schedule/date-meeting/${id}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 200) {
                return result.data;
            }
            else {
                return {
                    success: false,
                    result: `Error to get schedule meeting`
                };
            }
        }
        catch(ex) {
            return {
                success: false,
                result: `Error to get schedule meeting: [${ex}]`
            };
        }
    },

    async DateMeetingCreateUpdate(_, scheduleMeetingRequest) {

        try {
            const result = await Vue.prototype.$vanKirkApi.post(`schedule/date-meeting/create-update`, scheduleMeetingRequest,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 204) {

                let msg = "Register updated with success!";

                if (scheduleMeetingRequest.id === 0) {
                    msg = "Appointment created successfully";
                }

                return {
                    success: true,
                    message: msg
                };
            }
            else {
                return {
                    success: false,
                    message: "Error to create/update register!"
                };
            }
        }
        catch(error) {

            if (error.response && error.response.status === 400) {
                return {
                    success: false,
                    message: `Error to create/update register: [${error.response.data}]`
                };
            }
            else {

                return {
                    success: false,
                    message: `Error to create/update register: [${error}]`
                };
            }
        }
    },

    async DateMeetingDelete(_, id) {

        try {
            const result = await Vue.prototype.$vanKirkApi.delete(`schedule/date-meeting/${id}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 200) {

                return {
                    success: true,
                    message: "Register deleted with success!"
                };
            }
        }
        catch(error) {

            if (error.response && error.response.status === 400) {
                return {
                    success: false,
                    message: `Error to delete the register: [${error.response.data}]`
                };
            }
            else {

                return {
                    success: false,
                    message: `Error to delete the register: [${error}]`
                };
            }
        }
    },

    async AcceptMeeting(_, { idMeeting, idUser, statusId }) {

        try {
            const result = await Vue.prototype.$vanKirkApi.put(`schedule/date-meeting/accept-meeting/${idMeeting}/${idUser}/${statusId}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 204) {
                return {
                    success: true,
                    message: "Status updated with success!"
                };
            }
            else {
                return {
                    success: false,
                    message: "Error updating the status!"
                };
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error updating the status: [${ex}]`
            };
        }
    },
}

export default {
    namespaced: true,
    actions
}
