
import Vue from 'vue'

const getDefaultState = () => {
    return {
        productSubCategory: {
            id: 0,
            idProductCategory: 0,
            description: ""
        }
    }
};

const state = getDefaultState();

const actions = {

    resetState({ commit }) {
        commit('resetState');
    },

    saveState({ commit }, payload) {
        commit('saveState', payload);
    },

    async List() {

        try {
            const result = await Vue.prototype.$vanKirkApi.get(`product/productSubCategory/list`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                let listProductSubCategory = result.data.result;

                return listProductSubCategory;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error to list sub categories  : [${ex}]`
            };
        }
    },

    async ListByCategory(_, idProductCategory) {

        try {
            const result = await Vue.prototype.$vanKirkApi.get(`product/productSubCategory/listByCategory/${idProductCategory}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                let listProductSubCategory = result.data.result;

                return listProductSubCategory;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error to list sub categories  : [${ex}]`
            };
        }
    },

    async GetById(_, id) {

        try {
            const result = await Vue.prototype.$vanKirkApi.get(`product/productSubCategory/${id}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 200) {
                return result.data;
            }
            else {
                return {
                    success: false,
                    result: `Error to get productSubCategory`
                };
            }
        }
        catch(ex) {
            return {
                success: false,
                result: `Error to get productSubCategory: [${ex}]`
            };
        }
    },

    async CreateUpdate(_, productSubCategoryRequest) {

        try {
            const result = await Vue.prototype.$vanKirkApi.post(`product/productSubCategory/create-update`, productSubCategoryRequest,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 204) {

                let msg = "Register updated with success!";

                if (productSubCategoryRequest.id === 0) {
                    msg = "Record created successfully";
                }

                return {
                    success: true,
                    message: msg
                };
            }
            else {
                return {
                    success: false,
                    message: "Error to create/update register!"
                };
            }
        }
        catch(error) {

            if (error.response && error.response.status === 400) {
                return {
                    success: false,
                    message: `Error to create/update register: [${error.response.data}]`
                };
            }
            else {

                return {
                    success: false,
                    message: `Error to create/update register: [${error}]`
                };
            }
        }
    },

    async Delete(_, id) {

        try {
            const result = await Vue.prototype.$vanKirkApi.delete(`product/productSubCategory/${id}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 200) {

                return {
                    success: true,
                    message: "Register deleted with success!"
                };
            }
        }
        catch(error) {

            if (error.response && error.response.status === 400) {
                return {
                    success: false,
                    message: `Error to delete the register: [${error.response.data}]`
                };
            }
            else {

                return {
                    success: false,
                    message: `Error to delete the register: [${error}]`
                };
            }
        }
    },
}

const mutations = {

    saveState: (state, newValue) => {
        Object.assign(state.productSubCategory, newValue);
    },

    resetState: (state) => {
        Object.assign(state, getDefaultState());
    }
}

const getters = {

    searchState: (state) => {
        return state.productSubCategory;
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
