import Vue from 'vue'

const getDefaultState = () => {
    return {
        contractor: {
            id: 0,
            idContractor: 0,
            status: "",
            name: "",
            email: ""
        }
    }
};

const state = getDefaultState();

const actions = {

    resetState({ commit }) {
        commit('resetState');
    },

    saveState({ commit }, payload) {
        commit('saveState', payload);
    },

    async List() {

        try {
            const result = await Vue.prototype.$vanKirkApi.get("common/contractor/list",
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                let listContractor = result.data.result;

                return listContractor;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error to list contractor: [${ex}]`
            };
        }
    },

    async ListByCategory(_, idContractorCategory) {

        try {
            const result = await Vue.prototype.$vanKirkApi.get(`common/contractor/list-by-category/${idContractorCategory}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                let listContractor = result.data.result;

                return listContractor;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error to list contractor: [${ex}]`
            };
        }
    },

    async GetById(_, id) {

        try {
            const result = await Vue.prototype.$vanKirkApi.get(`common/contractor/${id}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 200) {
                return result.data;
            }
            else {
                return {
                    success: false,
                    result: `Error to get contractor`
                };
            }
        }
        catch(ex) {
            return {
                success: false,
                result: `Error to get contractor: [${ex}]`
            };
        }
    },

    async CreateUpdate(_, contractorRequest) {

        try {
            const result = await Vue.prototype.$vanKirkApi.post(`common/contractor/create-update`, contractorRequest,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 204) {

                let msg = "Register updated with success!";

                if (contractorRequest.id === 0) {
                    msg = "Record created successfully";
                }

                return {
                    success: true,
                    message: msg
                };
            }
            else {
                return {
                    success: false,
                    message: "Error to create/update register!"
                };
            }
        }
        catch(error) {

            if (error.response && error.response.status === 400) {
                return {
                    success: false,
                    message: `Error to create/update register: [${error.response.data}]`
                };
            }
            else {

                return {
                    success: false,
                    message: `Error to create/update register: [${error}]`
                };
            }
        }
    },

    async UpdateStatus(_, { id, statusId }) {

        try {
            const result = await Vue.prototype.$vanKirkApi.put(`common/contractor/update-status/${id}/${statusId}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 204) {
                return {
                    success: true,
                    message: "Status updated with success!"
                };
            }
            else {
                return {
                    success: false,
                    message: "Error updating the status!"
                };
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error updating the status: [${ex}]`
            };
        }
    },

    async ListContacts(_, idContractor) {

        try {
            const result = await Vue.prototype.$vanKirkApi.get(`common/contractor/list-contacts/${idContractor}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                let listContacts = result.data.result;

                return listContacts;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error to list contractor contacts: [${ex}]`
            };
        }
    },
}

const mutations = {

    saveState: (state, newValue) => {
        Object.assign(state.contractor, newValue);
    },

    resetState: (state) => {
        Object.assign(state, getDefaultState());
    }
}

const getters = {

    searchState: (state) => {
        return state.contractor;
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
