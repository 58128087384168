
export function permitCountyFormatServer(permitTemplateRequest) {

    if (permitTemplateRequest != null) {

        let newListPermit = [];

        if (permitTemplateRequest.listPermit !== null && permitTemplateRequest.listPermit !== undefined) {
            permitTemplateRequest.listPermit.forEach(itemPermit => {
                if (itemPermit && itemPermit.permitSelected) {
                    newListPermit.push({
                        id: itemPermit.id,
                        idPermit: itemPermit.permitSelected.id,
                    })

                    permitTemplateRequest.listPermit = newListPermit;
                }
            })
        }

        let newListCity = [];

        if (permitTemplateRequest.listCity !== null && permitTemplateRequest.listCity !== undefined) {
            permitTemplateRequest.listCity.forEach(itemCity => {
                if (itemCity && itemCity.citySelected) {
                    newListCity.push({
                        id: itemCity.id,
                        idCity: itemCity.citySelected.id,
                    })

                    permitTemplateRequest.listCity = newListCity;
                }
            })
        }
    }

    return permitTemplateRequest;
}
