
import Vue from 'vue'

const getDefaultState = () => {
    return {
        template: {
            id: 0,
            name: "",
            status: 0,
            public: 0,
            listItemRequest: [],
        }
    }
};

const state = getDefaultState();

const actions = {

    resetState({ commit }) {
        commit('resetState');
    },

    saveState({ commit }, payload) {
        commit('saveState', payload);
    },

    async List(_, filterPublicTemplates = false) {

        try {
            const result = await Vue.prototype.$vanKirkApi.get(`template/list/${filterPublicTemplates}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                let listTemplate = result.data.result;

                return listTemplate;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error to list template: [${ex}]`
            };
        }
    },

    async GetById(_, id) {

        try {
            const result = await Vue.prototype.$vanKirkApi.get(`template/${id}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 200) {
                return result.data;
            }
            else {
                return {
                    success: false,
                    result: `Error to get template`
                };
            }
        }
        catch(ex) {
            return {
                success: false,
                result: `Error to get template: [${ex}]`
            };
        }
    },

    async CreateUpdate(_, templateRequest) {

        try {

            const result = await Vue.prototype.$vanKirkApi.post(`template/create-update`, templateRequest,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 204) {

                let msg = "Register updated with success!";

                if (templateRequest.id === 0) {
                    msg = "Record created successfully";
                }

                return {
                    success: true,
                    message: msg
                };
            }
            else {
                return {
                    success: false,
                    message: "Error to create/update register!"
                };
            }
        }
        catch(error) {

            if (error.response && error.response.status === 400) {
                return {
                    success: false,
                    message: `Error to create/update register: [${error.response.data}]`
                };
            }
            else {

                return {
                    success: false,
                    message: `Error to create/update register: [${error}]`
                };
            }
        }
    },

    async UpdateStatus(_, { id, statusId }) {

        try {
            const result = await Vue.prototype.$vanKirkApi.put(`template/update-status/${id}/${statusId}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 204) {
                return {
                    success: true,
                    message: "Status updated with success!"
                };
            }
            else {
                return {
                    success: false,
                    message: "Error updating the status!"
                };
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error updating the status: [${ex}]`
            };
        }
    },
}

const mutations = {

    saveState: (state, newValue) => {
        Object.assign(state.template, newValue);
    },

    resetState: (state) => {
        Object.assign(state, getDefaultState());
    }
}

const getters = {

    searchState: (state) => {
        return state.template;
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
