import { formaterDecimalBRServer } from '@/utilities/Utils';

export function userFormatServer(userRequest) {

    if (userRequest != null) {

        userRequest.budgetProposal = formaterDecimalBRServer(userRequest.budgetProposal);
        userRequest.discount = formaterDecimalBRServer(userRequest.discount);
    }

    return userRequest;
}
