
import Vue from 'vue'

const getDefaultState = () => {
    return {
        state: {
            id: 0,
            code: "",
            description: ""
        }
    }
};

const state = getDefaultState();

const actions = {

    resetState({ commit }) {
        commit('resetState');
    },

    saveState({ commit }, payload) {
        commit('saveState', payload);
    },

    async List() {

        try {
            const result = await Vue.prototype.$vanKirkApi.get("common/state/list",
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                let listState = result.data.result;

                return listState;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error to list states: [${ex}]`
            };
        }
    },

    async GetById(_, id) {

        try {
            const result = await Vue.prototype.$vanKirkApi.get(`common/state/${id}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 200) {
                return result.data;
            }
            else {
                return {
                    success: false,
                    result: `Error to get state`
                };
            }
        }
        catch(ex) {
            return {
                success: false,
                result: `Error to get state: [${ex}]`
            };
        }
    },

    async CreateUpdate(_, stateRequest) {

        try {
            const result = await Vue.prototype.$vanKirkApi.post(`common/state/create-update`, stateRequest,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 204) {

                let msg = "Register updated with success!";

                if (stateRequest.id === 0) {
                    msg = "Record created successfully";
                }

                return {
                    success: true,
                    message: msg
                };
            }
            else {
                return {
                    success: false,
                    message: "Error to create/update register!"
                };
            }
        }
        catch(error) {

            if (error.response && error.response.status === 400) {
                return {
                    success: false,
                    message: `Error to create/update register: [${error.response.data}]`
                };
            }
            else {

                return {
                    success: false,
                    message: `Error to create/update register: [${error}]`
                };
            }
        }
    },

    async Delete(_, id) {

        try {
            const result = await Vue.prototype.$vanKirkApi.delete(`common/state/${id}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 200) {

                return {
                    success: true,
                    message: "Register deleted with success!"
                };
            }
        }
        catch(error) {

            if (error.response && error.response.status === 400) {
                return {
                    success: false,
                    message: `Error to delete the register: [${error.response.data}]`
                };
            }
            else {

                return {
                    success: false,
                    message: `Error to delete the register: [${error}]`
                };
            }
        }
    },
}

const mutations = {

    saveState: (state, newValue) => {
        Object.assign(state.state, newValue);
    },

    resetState: (state) => {
        Object.assign(state, getDefaultState());
    }
}

const getters = {

    searchState: (state) => {
        return state.state;
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
