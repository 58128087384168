<template>
    <div class="text-center">
		<v-dialog
			v-model="$store.state.modalToDo"
			transition="dialog-top-transition"
			persistent
			width="500"
			:fullscreen="$vuetify.breakpoint.mobile"
		>
		
			<v-card>
				<v-card-title class="text-h5 lighten-2" style="border-bottom: solid 1px var(--color__cinza); background-color: var(--color__red); color: #ffffff">
					Urgent Items
				</v-card-title>

				<v-card-text 
                    class="justify-center"
					style="margin-top: 70px; text-align: center;height: 100px !important;"
				>
					<label style="font-size: 20px;">You have <label style="font-size: 30px; color: var(--color__red);">{{ $store.state.qtdeToDo }}</label> pending items in your to-do list</label>
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
					<div style="width: 100%; text-align: center;">

						<v-btn
							class="mx-2"
							outlined
                            :to="{ name: 'toDoUrgent', params: { categoryName: ' - Urgent' } }" 
                            @click="setIDToDoCategory(1, 0, 0, 0)"
						>
							View Pending Items
						</v-btn>
					</div>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>

    import Helpers from '@/utilities/Helpers';

    export default {

        mixins: [Helpers],

        methods: {
            
            setIDToDoCategory(urgent, system, assignedToMe, idTodoCategory) {
                let jsonToDoCategory = {
                    urgent,
                    system,
                    assignedToMe,
                    idTodoCategory,
                    done: null
                }

                localStorage.setItem('jsonToDoCategory', JSON.stringify(jsonToDoCategory));

                this.hideModalToDo(this.$store.state.qtdeToDo);
            }
        },
    }
</script>


<style scoped>
    .v-dialog {
        border-radius: 15px;
    }
</style>