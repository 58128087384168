
export function profileFormatServer(profileRequest) {

    if (profileRequest != null) {

        let newListProfileMenu = [];

        if (profileRequest.listProfileMenu !== null && profileRequest.listProfileMenu !== undefined) {
            profileRequest.listProfileMenu.forEach(itemProfileMenu => {
                if (itemProfileMenu && itemProfileMenu.menuSelected) {
                    newListProfileMenu.push({
                        id: itemProfileMenu.id,
                        idMenu: itemProfileMenu.menuSelected.id,
                    })

                    profileRequest.listProfileMenu = newListProfileMenu;
                }
            })
        }
    }

    return profileRequest;
}
