
import Vue from 'vue'

const getDefaultState = () => {
    return {
        product: {
            id: 0,
            idProductCategory: 0,
            idProductSubCategory: 0,
            description: "",
            price: 0,
        }
    }
};

const state = getDefaultState();

const actions = {

    resetState({ commit }) {
        commit('resetState');
    },

    saveState({ commit }, payload) {
        commit('saveState', payload);
    },

    async List() {

        try {
            const result = await Vue.prototype.$vanKirkApi.get(`product/list`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                let listProduct = result.data.result;

                return listProduct;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error to list products: [${ex}]`
            };
        }
    },

    async GetById(_, id) {

        try {
            const result = await Vue.prototype.$vanKirkApi.get(`product/${id}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 200) {
                return result.data;
            }
            else {
                return {
                    success: false,
                    result: `Error to get product`
                };
            }
        }
        catch(ex) {
            return {
                success: false,
                result: `Error to get product: [${ex}]`
            };
        }
    },

    async CreateUpdate(_, productRequest) {

        try {
            const result = await Vue.prototype.$vanKirkApi.post(`product/create-update`, productRequest,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 200) {

                let msg = "Register updated with success!";

                if (productRequest.id === 0) {
                    msg = "Record created successfully";
                }

                return {
                    success: true,
                    idProduct: result.data.result,
                    message: msg
                };
            }
            else {
                return {
                    success: false,
                    idProduct: 0,
                    message: "Error to create/update register!"
                };
            }
        }
        catch(error) {

            if (error.response && error.response.status === 400) {
                return {
                    success: false,
                    message: `Error to create/update register: [${error.response.data}]`
                };
            }
            else {

                return {
                    success: false,
                    message: `Error to create/update register: [${error}]`
                };
            }
        }
    },

    async UpdateStatus(_, { id, statusId }) {

        try {
            const result = await Vue.prototype.$vanKirkApi.put(`product/update-status/${id}/${statusId}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 204) {
                return {
                    success: true,
                    message: "Status updated with success!"
                };
            }
            else {
                return {
                    success: false,
                    message: "Error updating the status!"
                };
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error updating the status: [${ex}]`
            };
        }
    },

    async ListProductsToScopeOfWork(_, { id, type, showOnlyCreatedItems, fromHistory } ) {

        try {
            const result = await Vue.prototype.$vanKirkApi.get(`product/list-products-to-scope-of-work/${id}/${type}/${showOnlyCreatedItems}/${fromHistory}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                let listProductsToScopeOfWork = result.data.result;

                return listProductsToScopeOfWork;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error to list products to scope of work: [${ex}]`
            };
        }
    },

    async ListProductsWithIconInProject(_, { idService, idServiceProject } ) {

        try {
            const result = await Vue.prototype.$vanKirkApi.get(`product/list-products-with-icons-in-project/${idService}/${idServiceProject}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                let listProductsWithIcons = result.data.result;

                return listProductsWithIcons;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error to list products with icons: [${ex}]`
            };
        }
    },
}

const mutations = {

    saveState: (state, newValue) => {
        Object.assign(state.product, newValue);
    },

    resetState: (state) => {
        Object.assign(state, getDefaultState());
    }
}

const getters = {

    searchState: (state) => {
        return state.product;
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
