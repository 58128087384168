
import Vue from 'vue'

const getDefaultState = () => {
    return {
        service: {
            id: 0,
            dateRegister: 0,
            idUserRegister: 0,
            idKindWork: 0,
            isSurvey: 0,
            isCustomer: 0,
            name: "",
            address: "",
            estimatedValueZillow: 0,
            phone: "",
            email: "",
            forecastStartDate: "",
            budget: 0,
            isCondominium: 0,
            condominium: "",
            notes: "",
            startDate: "",
            closeDate: "",
            status: "",
            idUserResponsible: ""
        }
    }
};

const state = getDefaultState();

const actions = {

    resetState({ commit }) {
        commit('resetState');
    },

    saveState({ commit }, payload) {
        commit('saveState', payload);
    },

    async List() {

        try {
            const result = await Vue.prototype.$vanKirkApi.get("service/list",
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                let listService = result.data.result;

                return listService;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error to list services: [${ex}]`
            };
        }
    },

    async ListCompletedJobs() {

        try {
            const result = await Vue.prototype.$vanKirkApi.get("service/list-completed-jobs",
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                let listService = result.data.result;

                return listService;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error to list jobs: [${ex}]`
            };
        }
    },

    async ListByCustomer(_, idCustomer) {

        try {
            const result = await Vue.prototype.$vanKirkApi.get(`service/list-by-customer/${idCustomer}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                let listCompletedJobs = result.data.result;

                return listCompletedJobs;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error to list completed jobs: [${ex}]`
            };
        }
    },

    async ListHistory(_, id) {

        try {
            const result = await Vue.prototype.$vanKirkApi.get(`service/list-history/${id}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                let listHistory = result.data.result;

                return listHistory;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error to list history of service: [${ex}]`
            };
        }
    },

    async ListProjectNote(_, { 
        idService, 
        idServiceProject, 
        onlyCheckExists, 
        idStatus, 
        idPanel, 
        idStage, 
        idUser 
    }) {

        try {
            
            const result = await Vue.prototype.$vanKirkApi.get(`service/list-project-note/${idService}/${idServiceProject}/${onlyCheckExists}/${idStatus}/${idPanel}/${idStage}/${idUser}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                let listProjectNotes = result.data.result;

                return listProjectNotes;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error to list notes of project: [${ex}]`
            };
        }
    },

    async AddProjectNote(_, noteRequest) {

        try {

            const result = await Vue.prototype.$vanKirkApi.post(`service/add-project-note`, noteRequest,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 200) {

                let msg = "Note updated with successfully";

                if (noteRequest.id === 0) {
                    msg = "Note created successfully";
                }

                return {
                    success: true,
                    idNote: result.data.result,
                    message: msg
                };
            }
            else {
                return {
                    success: false,
                    message: "Error to create/update note!"
                };
            }
        }
        catch(error) {

            if (error.response && error.response.status === 400) {
                return {
                    success: false,
                    message: `Error to create/update note: [${error.response.data}]`
                };
            }
            else {

                return {
                    success: false,
                    message: `Error to create/update note: [${error}]`
                };
            }
        }
    },

    async AddServiceNote(_, noteRequest) {

        try {

            const result = await Vue.prototype.$vanKirkApi.post(`service/add-service-note`, noteRequest,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 204) {

                let msg = "Note updated with successfully";

                if (noteRequest.id === 0) {
                    msg = "Note created successfully";
                }

                return {
                    success: true,
                    message: msg
                };
            }
            else {
                return {
                    success: false,
                    message: "Error to create/update note!"
                };
            }
        }
        catch(error) {

            if (error.response && error.response.status === 400) {
                return {
                    success: false,
                    message: `Error to create/update note: [${error.response.data}]`
                };
            }
            else {

                return {
                    success: false,
                    message: `Error to create/update note: [${error}]`
                };
            }
        }
    },

    async DeleteProjectNote(_, id) {

        try {
            const result = await Vue.prototype.$vanKirkApi.delete(`service/delete-project-note/${id}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 200) {

                return {
                    success: true,
                    message: "Note deleted with successfully"
                };
            }
        }
        catch(error) {

            if (error.response && error.response.status === 400) {
                return {
                    success: false,
                    message: `Error to delete the note: [${error.response.data}]`
                };
            }
            else {

                return {
                    success: false,
                    message: `Error to delete the note: [${error}]`
                };
            }
        }
    },

    async GetById(_, id) {

        try {
            const result = await Vue.prototype.$vanKirkApi.get(`service/${id}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 200) {
                return result.data;
            }
            else {
                return {
                    success: false,
                    result: `Error to get job`
                };
            }
        }
        catch(ex) {
            return {
                success: false,
                result: `Error to get job: [${ex}]`
            };
        }
    },

    async GetByGuid(_, guid) {

        try {
            const result = await Vue.prototype.$vanKirkApi.get(`service/get-by-guid/${guid}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 200) {
                return result.data;
            }
            else {
                return {
                    success: false,
                    result: `Error to get job`
                };
            }
        }
        catch(ex) {
            return {
                success: false,
                result: `Error to get job: [${ex}]`
            };
        }
    },

    async CreateUpdate(_, serviceRequest) {

        try {

            const result = await Vue.prototype.$vanKirkApi.post(`service/create-update`, serviceRequest,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 200) {

                let msg = "Register updated with successfully";

                if (serviceRequest.id === 0) {
                    msg = "Record created successfully";
                }

                return {
                    id: result.data.result,
                    success: true,
                    message: msg
                };
            }
            else {
                return {
                    success: false,
                    message: "Error to create/update register!"
                };
            }
        }
        catch(error) {

            if (error.response && error.response.status === 400) {
                return {
                    success: false,
                    message: `Error to create/update register: [${error.response.data}]`
                };
            }
            else {

                return {
                    success: false,
                    message: `Error to create/update register: [${error}]`
                };
            }
        }
    },

    async UpdateStatus(_, updateStatusRequest) {

        try {
            const result = await Vue.prototype.$vanKirkApi.put(`service/update-status/${updateStatusRequest.id}`, updateStatusRequest,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 204) {
                return {
                    success: true,
                    message: "Status updated with successfully"
                };
            }
            else {
                return {
                    success: false,
                    message: "Error updating the status!"
                };
            }
        }
        catch(error) {

            if (error.response && error.response.status === 400) {
                return {
                    success: false,
                    message: `Error updating the status: [${error.response.data}]`
                };
            }
            else {

                return {
                    success: false,
                    message: `Error updating the status: [${error}]`
                };
            }
        }
    },

    async UpdateProjectStatus(_, updateStatusRequest) {

        try {
            const result = await Vue.prototype.$vanKirkApi.put(`service/update-project-status/${updateStatusRequest.id}`, updateStatusRequest,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 204) {
                return {
                    success: true,
                    message: "Project status updated successfully"
                };
            }
            else {
                return {
                    success: false,
                    message: "Error updating the project status!"
                };
            }
        }
        catch(error) {

            if (error.response && error.response.status === 400) {
                return {
                    success: false,
                    message: `${error.response.data}`
                };
            }
            else {

                return {
                    success: false,
                    message: `Error updating the status: ${error}`
                };
            }
        }
    },

    async UpdateSituation(_, updateSituationRequest) {

        try {
            const result = await Vue.prototype.$vanKirkApi.put(`service/update-situation/${updateSituationRequest.id}`, updateSituationRequest,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 204) {
                return {
                    success: true,
                    message: "Job situation updated with successfully"
                };
            }
            else {
                return {
                    success: false,
                    message: "Error updating the job situation!"
                };
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error updating the job situation: [${ex}]`
            };
        }
    },

    async UpdateProjectSituation(_, updateSituationRequest) {

        try {
            const result = await Vue.prototype.$vanKirkApi.put(`service/update-project-situation/${updateSituationRequest.id}`, updateSituationRequest,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 204) {
                return {
                    success: true,
                    message: "Project situation updated with successfully"
                };
            }
            else {
                return {
                    success: false,
                    message: "Error updating the project situation!"
                };
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error updating the project situation: [${ex}]`
            };
        }
    },

    async UpdateServiceSituation(_, updateSituationRequest) {

        try {
            const result = await Vue.prototype.$vanKirkApi.put(`service/update-service-situation/${updateSituationRequest.id}`, updateSituationRequest,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 204) {
                return {
                    success: true,
                    message: "Situation updated with successfully"
                };
            }
            else {
                return {
                    success: false,
                    message: "Error updating the situation!"
                };
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error updating the situation: [${ex}]`
            };
        }
    },

    async ListAvaliableSellers(_, avaliableSellerRequest) {

        try {
            const result = await Vue.prototype.$vanKirkApi.post("service/list-avaliable-sellers", avaliableSellerRequest,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                let listAvaliableSellers = result.data.result;

                return listAvaliableSellers;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error to list sallers: [${ex}]`
            };
        }
    },

    async ListAvaliableDrafters() {

        try {
            const result = await Vue.prototype.$vanKirkApi.get("service/list-avaliable-drafters",
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                let listAvaliableDrafters = result.data.result;

                return listAvaliableDrafters;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error to list drafters: [${ex}]`
            };
        }
    },

    async ListAvaliablePermitters() {

        try {
            const result = await Vue.prototype.$vanKirkApi.get("service/list-avaliable-permitters",
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                let listAvaliablePermitters = result.data.result;

                return listAvaliablePermitters;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error to list permitters: [${ex}]`
            };
        }
    },

    async AssignSalesPerson(_, { id, idUserResponsible }) {

        try {
            const result = await Vue.prototype.$vanKirkApi.put(`service/assign-sales-person/${id}/${idUserResponsible}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 204) {
                return {
                    success: true,
                    message: "Sales Person assigned with successfully"
                };
            }
            else {
                return {
                    success: false,
                    message: "Error assign sales person!"
                };
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error assign sales person: [${ex}]`
            };
        }
    },

    async AssignTeam(_, listTeamRequest) {

        try {
            const result = await Vue.prototype.$vanKirkApi.post(`service/assign-team`, listTeamRequest,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 204) {
                return {
                    success: true,
                    message: "Team assigned with successfully"
                };
            }
            else {
                return {
                    success: false,
                    message: "Error assign Team!"
                };
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error assign Team: [${ex}]`
            };
        }
    },

    async GetTeam(_, id) {

        try {
            const result = await Vue.prototype.$vanKirkApi.get(`service/get-team/${id}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                let listTeam = result.data.result;

                return listTeam;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error to list team of service: [${ex}]`
            };
        }
    },

    async ListPipelineSalesPerson() {

        try {
            const result = await Vue.prototype.$vanKirkApi.post("service/list-pipeline-sales-person",
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                let listPipeline = result.data.result;

                return listPipeline;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error to list pipeline: [${ex}]`
            };
        }
    },

    async ListPipelineDrafting() {

        try {
            const result = await Vue.prototype.$vanKirkApi.post("service/list-pipeline-drafting",
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                let listPipeline = result.data.result;

                return listPipeline;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error to list pipeline: [${ex}]`
            };
        }
    },

    async ListPipelinePermitting() {

        try {
            const result = await Vue.prototype.$vanKirkApi.post("service/list-pipeline-permitting",
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                let listPipeline = result.data.result;

                return listPipeline;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error to list pipeline: [${ex}]`
            };
        }
    },

    async ListPipelineConstruction() {

        try {
            const result = await Vue.prototype.$vanKirkApi.post("service/list-pipeline-construction",
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                let listPipeline = result.data.result;

                return listPipeline;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error to list pipeline: [${ex}]`
            };
        }
    },

    async ListPipelineService() {

        try {
            const result = await Vue.prototype.$vanKirkApi.post("service/list-pipeline-service",
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                let listPipeline = result.data.result;

                return listPipeline;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error to list pipeline: [${ex}]`
            };
        }
    },

    async ListItems(_, id) {

        try {
            const result = await Vue.prototype.$vanKirkApi.get(`service/list-items/${id}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                let listItems = result.data.result;

                return listItems;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error to list items: [${ex}]`
            };
        }
    },

    async ListItemsCustom(_, id) {

        try {
            const result = await Vue.prototype.$vanKirkApi.get(`service/list-items-custom/${id}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                let listCustomItems = result.data.result;

                return listCustomItems;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error to list custom items: [${ex}]`
            };
        }
    },

    async ListItemsColorSelection(_, id) {

        try {
            const result = await Vue.prototype.$vanKirkApi.get(`service/list-items-color-selection/${id}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                let listItemsColorSelection = result.data.result;

                return listItemsColorSelection;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error to list color selection items: [${ex}]`
            };
        }
    },

    async CreateUpdateItems(_, { serviceItemRequest, createHistory, oficial, uploadAddendum }) {

        try {

            const result = await Vue.prototype.$vanKirkApi.post(`service/create-update-items/${createHistory}/${oficial}/${uploadAddendum}`, serviceItemRequest,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 204) {

                let msg = "Items updated with successfully";

                return {
                    success: true,
                    message: msg
                };
            }
            else {
                return {
                    success: false,
                    message: "Error to create/update items!"
                };
            }
        }
        catch(error) {

            if (error.response && error.response.status === 400) {
                return {
                    success: false,
                    message: `Error to create/update items: [${error.response.data}]`
                };
            }
            else {

                return {
                    success: false,
                    message: `Error to create/update items: [${error}]`
                };
            }
        }
    },

    async CreateUpdateItemsCustom(_, serviceItemCustomRequest) {

        try {

            const result = await Vue.prototype.$vanKirkApi.post(`service/create-update-items-custom`, serviceItemCustomRequest,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 204) {

                let msg = "Custom Items updated with successfully";

                return {
                    success: true,
                    message: msg
                };
            }
            else {
                return {
                    success: false,
                    message: "Error to create/update custom items!"
                };
            }
        }
        catch(error) {

            if (error.response && error.response.status === 400) {
                return {
                    success: false,
                    message: `Error to create/update custom items: [${error.response.data}]`
                };
            }
            else {

                return {
                    success: false,
                    message: `Error to create/update custom items: [${error}]`
                };
            }
        }
    },

    async UpdateItemsColorSelection(_, serviceItemRequest) {

        try {

            const result = await Vue.prototype.$vanKirkApi.post(`service/update-items-color-selection`, serviceItemRequest,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 204) {

                let msg = "Items updated with successfully";

                return {
                    success: true,
                    message: msg
                };
            }
            else {
                return {
                    success: false,
                    message: "Error to create/update items!"
                };
            }
        }
        catch(error) {

            if (error.response && error.response.status === 400) {
                return {
                    success: false,
                    message: `Error to create/update items: [${error.response.data}]`
                };
            }
            else {

                return {
                    success: false,
                    message: `Error to create/update items: [${error}]`
                };
            }
        }
    },

    async UpdateStep(_, {id, newStep}) {

        try {
            const result = await Vue.prototype.$vanKirkApi.put(`service/update-step/${id}/${newStep}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 204) {
                return {
                    success: true,
                    message: "Step updated with successfully"
                };
            }
            else {
                return {
                    success: false,
                    message: "Error updating the step!"
                };
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error updating the step: [${ex}]`
            };
        }
    },

    async CreateUpdateTechSpec(_, serviceTechSpecRequest) {

        try {

            const result = await Vue.prototype.$vanKirkApi.post(`service/create-update-tech-spec`, serviceTechSpecRequest,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 204) {

                let msg = "Technical Specification updated successfully";

                return {
                    success: true,
                    message: msg
                };
            }
            else {
                return {
                    success: false,
                    message: "Error to create/update Technical Specification!"
                };
            }
        }
        catch(error) {

            if (error.response && error.response.status === 400) {
                return {
                    success: false,
                    message: `Error to create/update Technical Specification: [${error.response.data}]`
                };
            }
            else {

                return {
                    success: false,
                    message: `Error to create/update Technical Specification: [${error}]`
                };
            }
        }
    },

    async UpdateNoteScopeOfWork(_, serviceRequest) {

        try {

            const result = await Vue.prototype.$vanKirkApi.post(`service/update-note-scope-of-work`, serviceRequest,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 204) {

                let msg = "Notes updated with successfully";

                return {
                    success: true,
                    message: msg
                };
            }
            else {
                return {
                    success: false,
                    message: "Error to create/update notes!"
                };
            }
        }
        catch(error) {

            if (error.response && error.response.status === 400) {
                return {
                    success: false,
                    message: `Error to create/update notes: [${error.response.data}]`
                };
            }
            else {

                return {
                    success: false,
                    message: `Error to create/update notes: [${error}]`
                };
            }
        }
    },

    async UpdateItemNote(_, serviceItemNoteRequest) {

        try {

            const result = await Vue.prototype.$vanKirkApi.post(`service/update-item-note`, serviceItemNoteRequest,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 204) {

                let msg = "Notes updated with successfully";

                return {
                    success: true,
                    message: msg
                };
            }
            else {
                return {
                    success: false,
                    message: "Error to create/update notes!"
                };
            }
        }
        catch(error) {

            if (error.response && error.response.status === 400) {
                return {
                    success: false,
                    message: `Error to create/update notes: [${error.response.data}]`
                };
            }
            else {

                return {
                    success: false,
                    message: `Error to create/update notes: [${error}]`
                };
            }
        }
    },

    async UpdateItemOptional(_, serviceItemNoteRequest) {

        try {

            const result = await Vue.prototype.$vanKirkApi.post(`service/update-item-optional`, serviceItemNoteRequest,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 204) {

                let msg = "Optional updated with successfully";

                return {
                    success: true,
                    message: msg
                };
            }
            else {
                return {
                    success: false,
                    message: "Error to create/update optional!"
                };
            }
        }
        catch(error) {

            if (error.response && error.response.status === 400) {
                return {
                    success: false,
                    message: `Error to create/update optional: [${error.response.data}]`
                };
            }
            else {

                return {
                    success: false,
                    message: `Error to create/update optional: [${error}]`
                };
            }
        }
    },

    async GetTechSpecById(_, id) {

        try {
            const result = await Vue.prototype.$vanKirkApi.get(`service/get-tech-spec/${id}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 200) {
                return result.data;
            }
            else {
                return {
                    success: false,
                    result: `Error to get service technical specification`
                };
            }
        }
        catch(ex) {
            return {
                success: false,
                result: `Error to get service technical specification: [${ex}]`
            };
        }
    },

    async CreateUpdateDrafting(_, serviceDraftingRequest) {

        try {

            const result = await Vue.prototype.$vanKirkApi.post(`service/create-update-drafting`, serviceDraftingRequest,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 204) {

                let msg = "Check List Drafting updated with successfully";

                return {
                    success: true,
                    message: msg
                };
            }
            else {
                return {
                    success: false,
                    message: "Error to create/update check list drafting!"
                };
            }
        }
        catch(error) {

            if (error.response && error.response.status === 400) {
                return {
                    success: false,
                    message: `Error to create/update check list drafting: [${error.response.data}]`
                };
            }
            else {

                return {
                    success: false,
                    message: `Error to create/update check list drafting: [${error}]`
                };
            }
        }
    },

    async GetDraftingById(_, id) {

        try {
            const result = await Vue.prototype.$vanKirkApi.get(`service/get-drafting/${id}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 200) {
                return result.data;
            }
            else {
                return {
                    success: false,
                    result: `Error to get service drafting`
                };
            }
        }
        catch(ex) {
            return {
                success: false,
                result: `Error to get service drafting: [${ex}]`
            };
        }
    },

    async CreateUpdateDraftingUploadFiles(_, serviceDraftingUploadFilesRequest) {

        try {

            const result = await Vue.prototype.$vanKirkApi.post(`service/create-update-drafting-upload-files`, serviceDraftingUploadFilesRequest,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 200) {

                let msg = "Upload Design updated with successfully";

                return {
                    id: result.data.result,
                    success: true,
                    message: msg
                };
            }
            else {
                return {
                    success: false,
                    message: "Error to create/update upload design!"
                };
            }
        }
        catch(error) {

            if (error.response && error.response.status === 400) {
                return {
                    success: false,
                    message: `Error to create/update upload files: [${error.response.data}]`
                };
            }
            else {

                return {
                    success: false,
                    message: `Error to create/update upload files: [${error}]`
                };
            }
        }
    },

    async GetDraftingUploadFilesById(_, id) {

        try {
            const result = await Vue.prototype.$vanKirkApi.get(`service/get-drafting-upload-files/${id}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 200) {
                return result.data;
            }
            else {
                return {
                    success: false,
                    result: `Error to get service upload files`
                };
            }
        }
        catch(ex) {
            return {
                success: false,
                result: `Error to get service upload files: [${ex}]`
            };
        }
    },

    async CreateUpdateLoadCalculation(_, serviceLoadCalculationRequest) {

        try {

            const result = await Vue.prototype.$vanKirkApi.post(`service/create-update-load-calculation`, serviceLoadCalculationRequest,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 200) {

                let msg = "Load Calculation updated with successfully";

                return {
                    id: result.data.result,
                    success: true,
                    message: msg
                };
            }
            else {
                return {
                    success: false,
                    message: "Error to create/update load calculation!"
                };
            }
        }
        catch(error) {

            if (error.response && error.response.status === 400) {
                return {
                    success: false,
                    message: `Error to create/update load calculation: [${error.response.data}]`
                };
            }
            else {

                return {
                    success: false,
                    message: `Error to create/update load calculation: [${error}]`
                };
            }
        }
    },

    async GetLoadCalculationById(_, { id, idAddress }) {

        try {
            const result = await Vue.prototype.$vanKirkApi.get(`service/get-load-calculation/${id}/${idAddress}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 200) {
                return result.data;
            }
            else {
                return {
                    success: false,
                    result: `Error to get service load calculation`
                };
            }
        }
        catch(ex) {
            return {
                success: false,
                result: `Error to get service load calculation: [${ex}]`
            };
        }
    },

    async SendEmailEngineer(_, id) {

        try {

            const result = await Vue.prototype.$vanKirkApi.post(`service/send-email-engineer/${id}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 204) {

                let msg = "E-mail to engineer sent with successfully!";

                return {
                    success: true,
                    message: msg
                };
            }
            else {
                return {
                    success: false,
                    message: "Error to send e-mail to engineer!"
                };
            }
        }
        catch(error) {

            if (error.response && error.response.status === 400) {
                return {
                    success: false,
                    message: `Error to send pool plan to engineer: [${error.response.data}]`
                };
            }
            else {

                return {
                    success: false,
                    message: `Error to send pool plan to engineer: [${error}]`
                };
            }
        }
    },

    async SendEmailLoadCalculation(_, id) {

        try {

            const result = await Vue.prototype.$vanKirkApi.post(`service/send-email-load-calculation/${id}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 204) {

                let msg = "E-mail to engineer sent with successfully!";

                return {
                    success: true,
                    message: msg
                };
            }
            else {
                return {
                    success: false,
                    message: "Error to send e-mail to engineer!"
                };
            }
        }
        catch(error) {

            if (error.response && error.response.status === 400) {
                return {
                    success: false,
                    message: `Error to send email with load calculation: [${error.response.data}]`
                };
            }
            else {

                return {
                    success: false,
                    message: `Error to send email with load calculation: [${error}]`
                };
            }
        }
    },

    async CreateUpdateServiceProject(_, serviceProjectRequest) {

        try {

            const result = await Vue.prototype.$vanKirkApi.post(`service/create-update-project`, serviceProjectRequest,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 200) {

                let msg = "Project updated with successfully";

                return {
                    id: result.data.result,
                    success: true,
                    message: msg
                };
            }
            else {
                return {
                    success: false,
                    message: "Error to create/update project!"
                };
            }
        }
        catch(error) {

            if (error.response && error.response.status === 400) {
                return {
                    success: false,
                    message: `Error to create/update project: [${error.response.data}]`
                };
            }
            else {

                return {
                    success: false,
                    message: `Error to create/update project: [${error}]`
                };
            }
        }
    },

    async GetServiceProjectById(_, id) {

        try {
            const result = await Vue.prototype.$vanKirkApi.get(`service/get-project/${id}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 200) {
                return result.data;
            }
            else {
                return {
                    success: false,
                    result: `Error to get project`
                };
            }
        }
        catch(ex) {
            return {
                success: false,
                result: `Error to get project: [${ex}]`
            };
        }
    },

    async ListProject(_, id) {

        try {
            const result = await Vue.prototype.$vanKirkApi.get(`service/list-projects/${id}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                let listProject = result.data.result;

                return listProject;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error to list projects: [${ex}]`
            };
        }
    },

    async RemoveProject(_, { id, removeAllVersions }) {

        try {
            const result = await Vue.prototype.$vanKirkApi.delete(`service/remove-project/${id}/${removeAllVersions}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 200) {

                return {
                    success: true,
                    message: "Project removed with successfully"
                };
            }
        }
        catch(error) {

            if (error.response && error.response.status === 400) {
                return {
                    success: false,
                    message: `Error to remove the project: [${error.response.data}]`
                };
            }
            else {

                return {
                    success: false,
                    message: `Error to remove the project: [${error}]`
                };
            }
        }
    },

    async ListProjectVersions(_, id) {

        try {
            const result = await Vue.prototype.$vanKirkApi.get(`service/list-project-versions/${id}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                let listProjectVersions = result.data.result;

                return listProjectVersions;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error to list project versions: [${ex}]`
            };
        }
    },

    async CreateUpdateServiceProjectVersionSnapShot(_, serviceProjectRequest) {

        try {

            const result = await Vue.prototype.$vanKirkApi.post(`service/create-update-project-version-snapshot`, serviceProjectRequest,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 200) {

                let msg = "Version updated with successfully";

                return {
                    id: result.data.result,
                    success: true,
                    message: msg
                };
            }
            else {
                return {
                    success: false,
                    message: "Error to create/update version!"
                };
            }
        }
        catch(error) {

            if (error.response && error.response.status === 400) {
                return {
                    success: false,
                    message: `Error to create/update version: [${error.response.data}]`
                };
            }
            else {

                return {
                    success: false,
                    message: `Error to create/update version: [${error}]`
                };
            }
        }
    },

    async SetCurrentVersion(_, { idServiceProject, currentVersion }) {

        try {
            const result = await Vue.prototype.$vanKirkApi.put(`service/set-current-version/${idServiceProject}/${currentVersion}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 204) {
                return {
                    success: true,
                    message: "Current Version changed with successfully!"
                };
            }
            else {
                return {
                    success: false,
                    message: "Error changing the current version!"
                };
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error changing the current version: [${ex}]`
            };
        }
    },

    async CheckAddendumCreated(_, id) {

        try {
            const result = await Vue.prototype.$vanKirkApi.get(`service/check-addendum-created/${id}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 200) {
                return result.data;
            }
            else {
                return {
                    success: false,
                    result: `Error to check addendum created`
                };
            }
        }
        catch(ex) {
            return {
                success: false,
                result: `Error to check addendum created: [${ex}]`
            };
        }
    },

    async ListAddendum(_, id) {

        try {
            const result = await Vue.prototype.$vanKirkApi.get(`service/list-addendum/${id}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                let listAddendum = result.data.result;

                return listAddendum;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error to list addendum: [${ex}]`
            };
        }
    },

    async UpdateClassification(_, { idServiceProject, classification }) {

        try {
            const result = await Vue.prototype.$vanKirkApi.put(`service/update-project-classification/${idServiceProject}/${classification}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 204) {
                return {
                    success: true,
                    message: "Classification updated with successfully!"
                };
            }
            else {
                return {
                    success: false,
                    message: "Error updating the classification!"
                };
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error updating the classification: [${ex}]`
            };
        }
    },

    async CreateUpdateColorSelection(_, serviceColorSelectionRequest) {

        try {

            const result = await Vue.prototype.$vanKirkApi.post(`service/create-update-color-selection`, serviceColorSelectionRequest,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 200) {

                let msg = "Color Selection updated with successfully";

                return {
                    id: result.data.result,
                    success: true,
                    message: msg
                };
            }
            else {
                return {
                    success: false,
                    message: "Error to create/update color selection!"
                };
            }
        }
        catch(error) {

            if (error.response && error.response.status === 400) {
                return {
                    success: false,
                    message: `Error to create/update color selection: [${error.response.data}]`
                };
            }
            else {

                return {
                    success: false,
                    message: `Error to create/update color selection: [${error}]`
                };
            }
        }
    },

    async GetColorSelectionById(_, idServiceProject) {

        try {
            const result = await Vue.prototype.$vanKirkApi.get(`service/get-color-selection/${idServiceProject}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 200) {
                return result.data;
            }
            else {
                return {
                    success: false,
                    result: `Error to get color selection`
                };
            }
        }
        catch(ex) {
            return {
                success: false,
                result: `Error to get color selection: [${ex}]`
            };
        }
    },

    async UpdateColorSelectionSigned(_, idServiceProjectColorSelection ) {

        try {
            const result = await Vue.prototype.$vanKirkApi.put(`service/update-color-selection-signed/${idServiceProjectColorSelection}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 204) {
                return {
                    success: true,
                    message: "Step updated with successfully"
                };
            }
            else {
                return {
                    success: false,
                    message: "Error updating the step!"
                };
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error updating the step: [${ex}]`
            };
        }
    },

    async CreateUpdatePermitting(_, servicePermittingRequest) {

        try {

            const result = await Vue.prototype.$vanKirkApi.post(`service/create-update-permitting`, servicePermittingRequest,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 204) {

                let msg = "Under Review updated with successfully";

                return {
                    success: true,
                    message: msg
                };
            }
            else {
                return {
                    success: false,
                    message: "Error to create/update under review!"
                };
            }
        }
        catch(error) {

            if (error.response && error.response.status === 400) {
                return {
                    success: false,
                    message: `Error to create/update under review: [${error.response.data}]`
                };
            }
            else {

                return {
                    success: false,
                    message: `Error to create/update under review: [${error}]`
                };
            }
        }
    },

    async GetPermittingById(_, id) {

        try {
            const result = await Vue.prototype.$vanKirkApi.get(`service/get-permitting/${id}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 200) {
                return result.data;
            }
            else {
                return {
                    success: false,
                    result: `Error to get under review`
                };
            }
        }
        catch(ex) {
            return {
                success: false,
                result: `Error to get under review: [${ex}]`
            };
        }
    },

    async CreateUpdatePermittingNotes(_, servicePermittingNotesRequest) {

        try {

            const result = await Vue.prototype.$vanKirkApi.post(`service/create-update-permitting-notes`, servicePermittingNotesRequest,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 204) {

                let msg = "Notes added with successfully";

                return {
                    success: true,
                    message: msg
                };
            }
            else {
                return {
                    success: false,
                    message: "Error to create/update notes!"
                };
            }
        }
        catch(error) {

            if (error.response && error.response.status === 400) {
                return {
                    success: false,
                    message: `Error to create/update notes: [${error.response.data}]`
                };
            }
            else {

                return {
                    success: false,
                    message: `Error to create/update notes: [${error}]`
                };
            }
        }
    },

    async GetPermittingNotesById(_, id) {

        try {
            const result = await Vue.prototype.$vanKirkApi.get(`service/get-permitting-notes/${id}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 200) {
                return result.data;
            }
            else {
                return {
                    success: false,
                    result: `Error to get notes`
                };
            }
        }
        catch(ex) {
            return {
                success: false,
                result: `Error to get notes: [${ex}]`
            };
        }
    },

    async ListProjectCompact(_, search) {

        try {
            const result = await Vue.prototype.$vanKirkApi.get(`service/list-project-compact/${search}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                let listProjects = result.data.result;

                return listProjects;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error to list projects: [${ex}]`
            };
        }
    },

    async ListConstructionPanel() {

        try {
            const result = await Vue.prototype.$vanKirkApi.get(`service/list-construction-panel`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                let listPanel = result.data.result;

                return listPanel;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error to list construction panel: [${ex}]`
            };
        }
    },

    async ListConstructionStages() {

        try {
            const result = await Vue.prototype.$vanKirkApi.get(`service/list-construction-stages`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                let listStages = result.data.result;

                return listStages;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error to list construction panel: [${ex}]`
            };
        }
    },

    async ListStatus() {

        try {
            const result = await Vue.prototype.$vanKirkApi.get(`service/list-service-status`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                let listStatus = result.data.result;

                return listStatus;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error to list job status: [${ex}]`
            };
        }
    },

    async SetProjectSupervisor(_, updateProjectSupervisor) {

        try {
            const result = await Vue.prototype.$vanKirkApi.put(`service/set-project-supervisor/${updateProjectSupervisor.id}`, updateProjectSupervisor,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 204) {
                return {
                    success: true,
                    message: "Supervisor assigned successfully"
                };
            }
            else {
                return {
                    success: false,
                    message: "Error to assign supervisor!"
                };
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error to assign supervisor: [${ex}]`
            };
        }
    },

    async ListClassification() {

        try {
            const result = await Vue.prototype.$vanKirkApi.get(`service/list-classification-notes`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                let listClassification = result.data.result;

                return listClassification;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error to list classification notes: [${ex}]`
            };
        }
    },
    
    async CreateUpdateNoteRead(_, listServiceProjectNoteReadRequest) {

        try {

            const result = await Vue.prototype.$vanKirkApi.post(`service/create-update-note-read`, listServiceProjectNoteReadRequest,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 200) {

                let msg = "Register updated with successfully";

                return {
                    success: true,
                    message: msg
                };
            }
            else {
                return {
                    success: false,
                    message: "Error to create/update register!"
                };
            }
        }
        catch(error) {

            if (error.response && error.response.status === 400) {
                return {
                    success: false,
                    message: `Error to create/update register: [${error.response.data}]`
                };
            }
            else {

                return {
                    success: false,
                    message: `Error to create/update register: [${error}]`
                };
            }
        }
    },

    async ListTypeOfWorks() {

        try {
            const result = await Vue.prototype.$vanKirkApi.get(`service/list-type-of-works`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                let listTypeOfWorks = result.data.result;

                return listTypeOfWorks;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error to list type of works: [${ex}]`
            };
        }
    },

    async SendScheduleOutlook(_, sendAppointmentRequest) {

        try {
            const result = await Vue.prototype.$vanKirkApi.post(`service/send-schedule-outlook`, sendAppointmentRequest,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 204) {
                return {
                    success: true,
                    message: "Send successfully"
                };
            }
            else {
                return {
                    success: false,
                    message: "Error to send appointment!"
                };
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error to send appointment: [${ex}]`
            };
        }
    },

    async CreateUpdateUnclosedDeal(_, serviceUnclosedDealRequest) {

        try {

            const result = await Vue.prototype.$vanKirkApi.post(`service/create-update-unclosed-deal`, serviceUnclosedDealRequest,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 204) {

                let msg = "Unclosed deal updated successfully";

                return {
                    success: true,
                    message: msg
                };
            }
            else {
                return {
                    success: false,
                    message: "Error to create/update unclosed deal!"
                };
            }
        }
        catch(error) {

            if (error.response && error.response.status === 400) {
                return {
                    success: false,
                    message: `Error to create/update unclosed deal: [${error.response.data}]`
                };
            }
            else {

                return {
                    success: false,
                    message: `Error to create/update unclosed deal: [${error}]`
                };
            }
        }
    },

    async ListUnclosedDeal() {

        try {
            const result = await Vue.prototype.$vanKirkApi.get("service/list-unclosed-deal",
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                let listServiceUnclosedDeal = result.data.result;

                return listServiceUnclosedDeal;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error to list unclosed deals: [${ex}]`
            };
        }
    },

    async GetUnclosedDealById(_, id) {

        try {
            const result = await Vue.prototype.$vanKirkApi.get(`service/get-unclosed-deal/${id}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 200) {
                return result.data;
            }
            else {
                return {
                    success: false,
                    result: `Error to get unclosed deal`
                };
            }
        }
        catch(ex) {
            return {
                success: false,
                result: `Error to get unclosed deal: [${ex}]`
            };
        }
    },

    async UpdateItemNotAutomatable(_, serviceItemNoteRequest) {

        try {

            const result = await Vue.prototype.$vanKirkApi.post(`service/update-item-not-automatable`, serviceItemNoteRequest,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 204) {

                let msg = "Optional updated with successfully";

                return {
                    success: true,
                    message: msg
                };
            }
            else {
                return {
                    success: false,
                    message: "Error to create/update optional!"
                };
            }
        }
        catch(error) {

            if (error.response && error.response.status === 400) {
                return {
                    success: false,
                    message: `Error to create/update optional: [${error.response.data}]`
                };
            }
            else {

                return {
                    success: false,
                    message: `Error to create/update optional: [${error}]`
                };
            }
        }
    },

    async ListTypeOfResponsibles() {

        try {
            const result = await Vue.prototype.$vanKirkApi.get(`service/list-type-of-responsibles`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                let listTypeOfResponsibles = result.data.result;

                return listTypeOfResponsibles;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error to list type of responsibles: [${ex}]`
            };
        }
    },

    async ListPermitsByProject(_, idServiceProject) {

        try {
            const result = await Vue.prototype.$vanKirkApi.get(`service/list-permits-by-project/${idServiceProject}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                let listPermits = result.data.result;

                return listPermits;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error to list permits: [${ex}]`
            };
        }
    },

    async ListCategoryOfColorSelection() {

        try {
            const result = await Vue.prototype.$vanKirkApi.get(`service/list-category-of-color-selection`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                let listCategories = result.data.result;

                return listCategories;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error to list categories of color selection: [${ex}]`
            };
        }
    },

    async ListPermitStages() {

        try {
            const result = await Vue.prototype.$vanKirkApi.get(`service/list-permit-stages`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                const listPermitStages = result.data.result;

                return listPermitStages;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error to list permit stages: [${ex}]`
            };
        }
    },

    async ListPermitStagesStatus() {

        try {
            const result = await Vue.prototype.$vanKirkApi.get(`service/list-permit-stages-status`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                const listPermitStagesStatus = result.data.result;

                return listPermitStagesStatus;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error to list permit stages status: [${ex}]`
            };
        }
    },

    async SetImportantMessage(_, { idService, serviceImportantMessageRequest }) {

        try {
            const result = await Vue.prototype.$vanKirkApi.put(`service/set-important-message/${idService}`, serviceImportantMessageRequest,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 204) {
                return {
                    success: true,
                    message: "Alert message updated with successfully!"
                };
            }
            else {
                return {
                    success: false,
                    message: "Error updating the alert message!"
                };
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error updating the alert message: [${ex}]`
            };
        }
    },

    async CreateUpdateHomeownerResponsability(_, serviceHOResponsabilityRequest) {

        try {

            const result = await Vue.prototype.$vanKirkApi.post(`service/create-update-homeowner-responsability`, serviceHOResponsabilityRequest,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 200) {

                let msg = "HO Responsability updated with successfully";

                return {
                    id: result.data.result,
                    success: true,
                    message: msg
                };
            }
            else {
                return {
                    success: false,
                    message: "Error to create/update HO Responsability!"
                };
            }
        }
        catch(error) {

            if (error.response && error.response.status === 400) {
                return {
                    success: false,
                    message: `Error to create/update HO Responsability: [${error.response.data}]`
                };
            }
            else {

                return {
                    success: false,
                    message: `Error to create/update HO Responsability: [${error}]`
                };
            }
        }
    },

    async GetColorHomeownerResponsabilityById(_, idService) {

        try {
            const result = await Vue.prototype.$vanKirkApi.get(`service/get-homeowner-responsability/${idService}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 200) {
                return result.data;
            }
            else {
                return {
                    success: false,
                    result: `Error to get homeowner responsability`
                };
            }
        }
        catch(ex) {
            return {
                success: false,
                result: `Error to get homeowner responsability: [${ex}]`
            };
        }
    },

    async ListResponsibles() {

        try {
            const result = await Vue.prototype.$vanKirkApi.get(`service/list-responsibles`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                let listResponsibles = result.data.result;

                return listResponsibles;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error to list of responsibles: [${ex}]`
            };
        }
    },

    async UpdatePermitFee(_, { idService, permitFeeRequest }) {

        try {
            const result = await Vue.prototype.$vanKirkApi.put(`service/update-permit-fee/${idService}`, permitFeeRequest,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 204) {
                return {
                    success: true,
                    message: "Permit Fee updated with successfully!"
                };
            }
            else {
                return {
                    success: false,
                    message: "Error updating the permit fee!"
                };
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error updating the permit fee: [${ex}]`
            };
        }
    },

    async UpdateAppointment(_, { idService, appointmentRequest }) {

        try {
            const result = await Vue.prototype.$vanKirkApi.put(`service/update-appointment/${idService}`, appointmentRequest,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 204) {
                return {
                    success: true,
                    message: "Appointment updated with successfully!"
                };
            }
            else {
                return {
                    success: false,
                    message: "Error updating the appointment!"
                };
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error updating the appointment: [${ex}]`
            };
        }
    },
}

const mutations = {

    saveState: (state, newValue) => {
        Object.assign(state.service, newValue);
    },

    resetState: (state) => {
        Object.assign(state, getDefaultState());
    }
}

const getters = {

    searchState: (state) => {
        return state.service;
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
