
import Vue from 'vue'

const getDefaultState = () => {
    return {
        module: {
            id: 0,
        }
    }
};

const state = getDefaultState();

const actions = {

    resetState({ commit }) {
        commit('resetState');
    },

    saveState({ commit }, payload) {
        commit('saveState', payload);
    },

    async List() {

        try {
            const result = await Vue.prototype.$vanKirkApi.get("common/module/list-module",
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                let listModule = result.data.result;

                return listModule;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error to list modules: [${ex}]`
            };
        }
    },
}

const mutations = {

    saveState: (state, newValue) => {
        Object.assign(state.module, newValue);
    },

    resetState: (state) => {
        Object.assign(state, getDefaultState());
    }
}

const getters = {

    searchState: (state) => {
        return state.module;
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}