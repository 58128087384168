
import Vue from 'vue'

const getDefaultState = () => {
    return {
        attachment: {
            id: 0,
            idKey: 0,
            fileName: "",
            typeDocument: 0
        }
    }
};

const state = getDefaultState();

const actions = {

    resetState({ commit }) {
        commit('resetState');
    },

    saveState({ commit }, payload) {
        commit('saveState', payload);
    },

    async List() {

        try {
            const result = await Vue.prototype.$vanKirkApi.get("attachments/list",
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                let listAttachment = result.data.result;

                return listAttachment;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error to list attachment: [${ex}]`
            };
        }
    },

    async GetById(_, id) {

        try {
            const result = await Vue.prototype.$vanKirkApi.get(`attachments/${id}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 200) {
                return result.data;
            }
            else {
                return {
                    success: false,
                    result: `Error to get attachment`
                };
            }
        }
        catch(ex) {
            return {
                success: false,
                result: `Error to get attachment: [${ex}]`
            };
        }
    },

    async GetAll(_, { typeAttachment, idKey }) {

        try {

            const result = await Vue.prototype.$vanKirkApi.get(`attachments/get-all/${typeAttachment}/${idKey}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                let listAttachment = result.data.result;

                return listAttachment;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                result: `Error to get list attachments: [${ex}]`
            };
        }
    },

    async GetAllByDocument(_, { typeAttachment, typeDocument, idKey }) {

        try {

            const result = await Vue.prototype.$vanKirkApi.get(`attachments/get-all-by-document/${typeAttachment}/${typeDocument}/${idKey}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                let listAttachment = result.data.result;

                return listAttachment;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                result: `Error to get list attachments: [${ex}]`
            };
        }
    },

    async CreateUpdate(_, { attachmentFileRequest, id }) {

        try {

            const result = await Vue.prototype.$vanKirkApi.post(`attachments/create-update`, attachmentFileRequest,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 204) {

                let msg = "Register updated with success!";

                if (id === 0) {
                    msg = "Record created successfully";
                }

                return {
                    success: true,
                    message: msg
                };
            }
            else {
                return {
                    success: false,
                    message: "Error to create/update register!"
                };
            }
        }
        catch(error) {

            if (error.response && error.response.status === 400) {
                return {
                    success: false,
                    message: `Error to create/update register: [${error.response.data}]`
                };
            }
            else {

                return {
                    success: false,
                    message: `Error to create/update register: [${error}]`
                };
            }
        }
    },

    async Delete(_, id) {

        try {
            const result = await Vue.prototype.$vanKirkApi.delete(`attachments/${id}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 200) {

                return {
                    success: true,
                    message: "Register deleted with success!"
                };
            }
        }
        catch(error) {

            if (error.response && error.response.status === 400) {
                return {
                    success: false,
                    message: `Error to delete the register: [${error.response.data}]`
                };
            }
            else {

                return {
                    success: false,
                    message: `Error to delete the register: [${error}]`
                };
            }
        }
    },
}

const mutations = {

    saveState: (state, newValue) => {
        Object.assign(state.attachment, newValue);
    },

    resetState: (state) => {
        Object.assign(state, getDefaultState());
    }
}

const getters = {

    searchState: (state) => {
        return state.attachment;
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
