
import Vue from 'vue'

const getDefaultState = () => {
    return {
        user: {
          id: 0,
          nome: "",
          email: "",
        },

        menu: {
            reload: false,
            itemGrupoMenu: null
        }
    }
};

const state = getDefaultState();

const actions = {

    resetState({ commit }) {
        commit('resetState');
    },

    saveState({ commit }, payload) {
        commit('saveState', payload);
    },

    async Logout({ commit }) {
        localStorage.clear();
        Vue.prototype.getRoot.isAuthenticated = false;
        Vue.prototype.getRoot.userInfo = null;
        commit('saveState', null);
    },

    async Auth( { commit }, loginRequest) {

        try {
            const result = await Vue.prototype.$vanKirkApi.post("login/auth", loginRequest,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 200) {

                let loginResponse = result.data;
                let idUser = loginResponse.idUser;

                localStorage.setItem("iduser", idUser);
                localStorage.setItem("token", loginResponse.token);
                Vue.prototype.getRoot.isAuthenticated = true;
                loginResponse = await actions.UserById({ commit }, idUser);

                return {
                    success: true,
                    message: "Successful Login!",
                    data: loginResponse
                };
            }
            else {
                return {
                    success: false,
                    message: "Invalid user or password!",
                    data: null
                };
            }
        }
        catch(ex) {
            return {
                success: false,
                message: "Invalid user or password!",
                data: null
            };
        }
    },

    async UserById( { commit }, idUser) {

        try {
            const result = await Vue.prototype.$vanKirkApi.get(`user/${idUser}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 200) {

                let loginResponse = result.data;

                localStorage.setItem("iduser", loginResponse.id);
                Vue.prototype.getRoot.isAuthenticated = true;
                Vue.prototype.getRoot.userInfo = loginResponse;

                commit('saveState', loginResponse);

                return loginResponse;
            }
            else {
                return false
            }
        }
        catch(ex) {
            console.log("Error validating user", ex);
            return false;
        }
    },

    async RecoveryPassword( _, requestRecovery) {

        try {
            const result = await Vue.prototype.$vanKirkApi.post("login/recovery-password", requestRecovery,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 200) {

                return {
                    success: true,
                    withApprove: result.data,
                    message: "An email has been sent with password recovery instructions.",
                };
            }
            else {
                return {
                    success: false,
                    withApprove: false,
                    message: "E-mail not found"
                };
            }
        }
        catch(error) {

            if (error.response && error.response.status === 400) {
                return {
                    success: false,
                    message: `Error to recovery password: [${error.response.data}]`
                };
            }
            else {

                return {
                    success: false,
                    message: `Error to recovery password: [${error}]`
                };
            }
        }
    },
}

const mutations = {

    saveState: (state, newValue) => {
        Object.assign(state.user, newValue);
    },

    resetState: (state) => {
        Object.assign(state, getDefaultState());
    }
}

const getters = {

    searchState: (state) => {
        return state.user;
    },

    menuState: state => {
        return state.menu;
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
