
const listRoutes = [
    {
        path: '*', 
        meta: { requiresAuth: false },
        redirect: { path: '/login' }
    },
    {
        path: '/404',
        meta: { requiresAuth: false },
        name: 'NotFound',
        component: () => import( /* webpackChunkName: "NotFound" */ `@/views/Login/NotFound.vue` )
    },
    {
        path: '/403',
        meta: { requiresAuth: false },
        name: 'AccessDenied',
        component: () => import(/* webpackChunkName: "Deny" */ `@/views/Login/Deny.vue` )
    },
    {
        path: '/500',
        name: 'ServerError',
        meta: { requiresAuth: false },
        component: () => import(/* webpackChunkName: "Error" */ `@/views/Login/Error.vue` )
    },
    
    {
        path: '/',
        meta: { requiresAuth: true },
        component: () => import(/*webpackChunkName: "Dashboard" */ '@/views/Main/Dashboard'),
    },

    { 
        path: '/login',
        name: 'login',
        props: false,
        component: () => import(/* webpackChunkName: "login" */ '@/views/Login/Login.vue') 
    },

    { 
        path: '/logout',
        name: 'logout',
        props: false,
        component: () => import(/* webpackChunkName: "logout" */ '@/views/Login/Logout.vue') 
    },

    { 
        path: '/Validate/:nextUrl?',
        name: 'Validate',
        props: true,
        component: () => import(/*webpackChunkName: "Validate" */ '@/views/Login/Validate') 
    },
    
    {
        path: '/user/config',
        name: 'userConfig',
        meta: { requiresAuth: true },
        component: () => import(/*webpackChunkName: "Configuration" */ '@/views/User/Configuration'),
    },
    
    {
        path: '/user/profile',
        name: 'userProfile',
        meta: { requiresAuth: true },
        component: () => import(/*webpackChunkName: "Profile" */ '@/views/User/Profile'),
    },
    
    {
        path: '/user/changePassword',
        name: 'changePassword',
        meta: { requiresAuth: true },
        component: () => import(/*webpackChunkName: "ChangePassword" */ '@/views/User/ChangePassword'),
    },
    
    {
        path: '/user/resetPassword/:token',
        name: 'resetPassword',
        props: true,
        component: () => import(/*webpackChunkName: "ResetPassword" */ '@/views/User/ResetPassword'),
    },
    
    {
        path: '/toDo/:categoryName?',
        name: 'toDoUrgent',
        meta: { requiresAuth: true },
        props: true,
        component: () => import(/*webpackChunkName: "ToDoUrgent" */ '@/views/ToDo/ToDoList'),
    },
    
    {
        path: '/toDo/:categoryName?',
        name: 'toDoSystem',
        meta: { requiresAuth: true },
        props: true,
        component: () => import(/*webpackChunkName: "ToDoSystem" */ '@/views/ToDo/ToDoList'),
    },
    
    {
        path: '/toDo/:categoryName?',
        name: 'toDoAssignedToMe',
        meta: { requiresAuth: true },
        props: true,
        component: () => import(/*webpackChunkName: "ToDoAssignedToMe" */ '@/views/ToDo/ToDoList'),
    },
    
    {
        path: '/customer/trackerPage/:id',
        name: 'trackerPage',
        props: true,
        component: () => import(/*webpackChunkName: "TrackerPage" */ '@/views/Customer/TrackerPage'),
    },
    
    {
        path: '/projectSpecifics/:id',
        name: 'projectSpecifics',
        props: true,
        component: () => import(/*webpackChunkName: "ProjectSpecifics" */ '@/views/Service/ProjectSpecifics'),
    },
]

let listRoutesFromLocalStorage = localStorage.getItem('listRouters')

if (listRoutesFromLocalStorage !== null && listRoutesFromLocalStorage !== undefined) {
    let listRoutesJSON = JSON.parse(listRoutesFromLocalStorage)

    listRoutesJSON.forEach(itemMenu => {
        
        listRoutes.push({
            path: itemMenu.path,
            name: itemMenu.name,
            props: itemMenu.props,
            meta: { requiresAuth: itemMenu.requiresAuth },
            component: () => import( /*webpackChunkName: "application" */ `@/views/${itemMenu.urlFile}`),
        })
    });
}

export default listRoutes;
