
import Vue from 'vue'

const getDefaultState = () => {
    return {
        inspection: {
          id: 0,
        },
    }
};

const state = getDefaultState();

const actions = {

    resetState({ commit }) {
        commit('resetState');
    },

    saveState({ commit }, payload) {
        commit('saveState', payload);
    },

    async GetById( { commit }, idInspection) {

        try {
            const result = await Vue.prototype.$vanKirkApi.get(`inspection/${idInspection}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 200) {

                let inspectionResponse = result.data;

                commit('saveState', inspectionResponse);

                return inspectionResponse;
            }
            else {
                return false
            }
        }
        catch(ex) {
            console.log("Error to get inspection", ex);
            return false;
        }
    },

    async ListNotification() {

        try {

            const result = await Vue.prototype.$vanKirkApi.get("inspection/list-notification",
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                return result.data.result;
            }
            else {
                return 0;
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error to list notification inspection: [${ex}]`
            };
        }
    },

    async List() {

        try {

            const result = await Vue.prototype.$vanKirkApi.get("inspection/list",
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                let listInspection = result.data.result;
                return listInspection;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error to list inspection: [${ex}]`
            };
        }
    },

    async CreateUpdate(_, inspectionRequest) {

        try {

            const result = await Vue.prototype.$vanKirkApi.post(`inspection/create-update`, inspectionRequest,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 200) {

                let msg = "Register updated with success!";

                if (inspectionRequest.id === 0) {
                    msg = "Record created successfully";
                }

                return {
                    success: true,
                    inspectionResponse: result.data.result,
                    message: msg
                };
            }
            else {
                return {
                    success: false,
                    message: "Error to create/update register!"
                };
            }
        }
        catch(error) {

            if (error.response && error.response.status === 400) {
                return {
                    success: false,
                    message: `Error to create/update register: [${error.response.data}]`
                };
            }
            else {

                return {
                    success: false,
                    message: `Error to create/update register: [${error}]`
                };
            }
        }
    },

    async UpdateStatus(_, updateStatusRequest) {

        try {
            const result = await Vue.prototype.$vanKirkApi.put(`inspection/update-status/${updateStatusRequest.id}`, updateStatusRequest,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 204) {
                return {
                    success: true,
                    message: "Status updated with successfully"
                };
            }
            else {
                return {
                    success: false,
                    message: "Error updating the status!"
                };
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error updating the status: [${ex}]`
            };
        }
    },

    async ListStatus() {

        try {
            const result = await Vue.prototype.$vanKirkApi.get(`inspection/list-status`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                let listStatus = result.data.result;

                return listStatus;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error to list status: [${ex}]`
            };
        }
    },
}

const mutations = {

    saveState: (state, newValue) => {
        Object.assign(state.inspection, newValue);
    },

    resetState: (state) => {
        Object.assign(state, getDefaultState());
    }
}

const getters = {

    searchState: (state) => {
        return state.inspection;
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
