
import Vue from 'vue'

const getDefaultState = () => {
    return {
        toDo: {
            id: 0,
            idUserRegister: 0,
            idUserResponsible: 0,
            idTodoCategory: 0,
            dateToDo: "",
            urgent: false,
            system: false,
            assignedToMe: false,
            title: "",
            description: "",
            action: "",
            read: false
        }
    }
};

const state = getDefaultState();

const actions = {

    resetState({ commit }) {
        commit('resetState');
    },

    saveState({ commit }, payload) {
        commit('saveState', payload);
    },

    async List() {

        try {
            const result = await Vue.prototype.$vanKirkApi.get("toDo/list",
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                let listToDo = result.data.result;

                return listToDo;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error to list to do: [${ex}]`
            };
        }
    },

    async ListByCategory(_, toDoCategoryRequest) {

        try {
            const result = await Vue.prototype.$vanKirkApi.post("toDo/listByCategory", toDoCategoryRequest,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.data) {
                let listToDo = result.data.result;

                return listToDo;
            }
            else {
                return [];
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error to list to do: [${ex}]`
            };
        }
    },

    async GetById(_, id) {

        try {
            const result = await Vue.prototype.$vanKirkApi.get(`toDo/${id}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 200) {
                return result.data;
            }
            else {
                return {
                    success: false,
                    result: `Error to get to do`
                };
            }
        }
        catch(ex) {
            return {
                success: false,
                result: `Error to get to do: [${ex}]`
            };
        }
    },

    async CreateUpdate(_, toDoRequest) {

        try {

            const result = await Vue.prototype.$vanKirkApi.post(`toDo/create-update`, toDoRequest,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 204) {

                let msg = "Register updated with success!";

                if (toDoRequest.id === 0) {
                    msg = "Record created successfully";
                }

                return {
                    success: true,
                    message: msg
                };
            }
            else {
                return {
                    success: false,
                    message: "Error to create/update register!"
                };
            }
        }
        catch(error) {

            if (error.response && error.response.status === 400) {
                return {
                    success: false,
                    message: `Error to create/update register: [${error.response.data}]`
                };
            }
            else {

                return {
                    success: false,
                    message: `Error to create/update register: [${error}]`
                };
            }
        }
    },

    async MarkAsReadUnRead(_, id) {

        try {
            const result = await Vue.prototype.$vanKirkApi.put(`toDo/mark-as-read-unread/${id}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 204) {
                return {
                    success: true,
                    message: "To Do updated with success!"
                };
            }
            else {
                return {
                    success: false,
                    message: "Error updating the to do!"
                };
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error updating the to do: [${ex}]`
            };
        }
    },

    async MarkAsDoneUnDone(_, id) {

        try {
            const result = await Vue.prototype.$vanKirkApi.put(`toDo/mark-as-done-undone/${id}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 204) {
                return {
                    success: true,
                    message: "To Do updated with success!"
                };
            }
            else {
                return {
                    success: false,
                    message: "Error updating the to do!"
                };
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error updating the to do: [${ex}]`
            };
        }
    },

    async Delete(_, id) {

        try {
            const result = await Vue.prototype.$vanKirkApi.delete(`toDo/${id}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            if (result.status === 200) {

                return {
                    success: true,
                    message: "Register deleted with success!"
                };
            }
        }
        catch(error) {

            if (error.response && error.response.status === 400) {
                return {
                    success: false,
                    message: `Error to delete the register: [${error.response.data}]`
                };
            }
            else {

                return {
                    success: false,
                    message: `Error to delete the register: [${error}]`
                };
            }
        }
    },

    async Clone(_, id) {

        try {
            const result = await Vue.prototype.$vanKirkApi.post(`toDo/clone/${id}`,
            {
                headers: {
                    "content-type": "application/json"
                }
            });

            console.log('resultClone', result);

            if (result.status === 200) {
                return {
                    id: result.data.result.id,
                    success: true,
                    message: "To Do cloned with success!"
                };
            }
            else {
                return {
                    id: 0,
                    success: false,
                    message: "Error cloning the to do!"
                };
            }
        }
        catch(ex) {
            return {
                success: false,
                message: `Error cloning the to do: [${ex}]`
            };
        }
    },
}

const mutations = {

    saveState: (state, newValue) => {
        Object.assign(state.toDo, newValue);
    },

    resetState: (state) => {
        Object.assign(state, getDefaultState());
    }
}

const getters = {

    searchState: (state) => {
        return state.toDo;
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
