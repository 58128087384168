<template>
    <v-app-bar 
        app 
        style="border-bottom: solid 1px var(--color__cinza) !important; height: 68px !important;"
    >
        <v-app-bar-nav-icon
            v-if="!$store.state.menuSide || $vuetify.breakpoint.mobile == true"

            @click.stop="openCloseSideMenu" style="border: none;padding:10px; margin-top: 10px;">
            <v-icon 
                color="var(--color__cinza_escuro)"
                v-if="!$store.state.menuSide && $vuetify.breakpoint.mobile == false"
            >
                mdi-playlist-remove
            </v-icon>
            <v-icon 
                color="var(--color__cinza_escuro)"
                v-else
            >
                mdi-playlist-plus
            </v-icon>
        </v-app-bar-nav-icon>

        <v-tabs
            v-model="subMenu"
            color="var(--color__main)"
        >
            <v-tab 
                v-for="itemGrupoMenu in userLoggedGetters.listGrupoMenu.filter(grp => grp.visible == 1)" :key="itemGrupoMenu.idGrupoMenu"
                @click="activeSubMenu(itemGrupoMenu)"
            >
                {{ itemGrupoMenu.description }}
            </v-tab>
        </v-tabs>

        <v-spacer></v-spacer>

        <v-col 
            style="margin-top: 30px;"
            cols="12"
            lg="3"
            md="12"
            sm="12"
        >
            <v-autocomplete
                v-model="projectSelected"
                :items="listProjects"
                :loading="isLoading"
                :search-input.sync="searchProject"
                hide-selected
                :no-data-text="textNoData"
                item-text="description"
                item-value="id"
                placeholder="Project search"
                prepend-inner-icon="mdi mdi-magnify"
                outlined
                return-object
                dense
                @keyup.enter="openProject"
                @change="openProject"
            ></v-autocomplete>
        </v-col>
        
        <div class="hideInMobile" style="padding:10px; margin-top: 10px; width: 300px; text-align: right; font-size: 12px;">
            <label>{{ userLoggedGetters.profile.name }}</label>
            <br />
            <label style="font-weight: normal !important;">{{ userLoggedGetters.name }}</label>
        </div>

        <v-menu offset-y :nudge-width="250" closeOnClick closeOnContentClick>
            
            <template v-slot:activator="{ on }">
                <div style="padding:10px; margin-top: 10px;">
                    <v-btn fab icon outlined v-on="on">
                        <v-icon>mdi-account</v-icon>
                    </v-btn>
                </div>
            </template>

            <v-list class="pa-0" color="#424242">
                <v-list-item style="height: 100px; text-align: center;">
                    <v-list-item-title>
                        <span style="font-size: 13px; color: silver"> {{ userLoggedGetters.profile.name }} </span>
                        <br />
                        <br />
                        <span style="font-size: 15px;"> {{ userLoggedGetters.email }} </span>
                    </v-list-item-title>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item to="/user/profile" ripple="ripple" rel="noopener">
                    <v-list-item-title>
                        <v-icon left>mdi-account-tie</v-icon>
                        My Information
                    </v-list-item-title>
                </v-list-item>

                <v-list-item to="/user/changePassword" ripple="ripple" rel="noopener">
                    <v-list-item-title>
                        <v-icon left>mdi-lock-outline</v-icon>
                        Password
                    </v-list-item-title>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item to="/logout" color="red" ripple="ripple" rel="noopener">
                    <v-list-item-title>
                        <v-icon left>mdi-logout</v-icon>
                        Logout
                    </v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
    </v-app-bar>
</template>

<script>

    import { mapGetters } from 'vuex';
    import Helpers from '@/utilities/Helpers';

    export default {

        mixins: [Helpers],
        
        data: () => ({

            textNoData: 'Type to search a project...',
            projectSelected: null,
            searchProject: null,
            listProjects: [],
            isLoading: false,

            subMenu: 0,
        }),

        computed: {
            ...mapGetters('logInModule', { userLoggedGetters: 'searchState', menuGetter: 'menuState' }),
        },

        watch: {
            async searchProject(search) {
                
                if (search == null || search == undefined || search.toString().trim() == '') {
                    this.listProjects = [];
                    this.textNoData = "Type to search a project...";
                }
                else {

                    if (this.listProjects.length <= 0) {
                        this.textNoData = "No projects found";
                    }

                    this.isLoading = true;

                    this.listProjects = await this.$store.dispatch("serviceModule/ListProjectCompact", search);

                    this.isLoading = false;
                }
            }
        },

        methods: {

            openCloseSideMenu() {
                var menuSide = !this.$store.state.menuSide;
                this.openSideMenu(menuSide)
            },

            activeSubMenu(itemGrupoMenu) {
                this.subMenu = 0;
                let index = 0;
                this.userLoggedGetters.listGrupoMenu.forEach(itemGrupoMenuFilter => {
                    if (itemGrupoMenuFilter.description.includes(itemGrupoMenu.description)) {
                        this.subMenu = index;
                    }
                    index++
                });

                this.menuGetter.reload = !this.menuGetter.reload;
                this.menuGetter.itemGrupoMenu = itemGrupoMenu;
            },

            openProject() {

                if (this.projectSelected != null && this.projectSelected != undefined) {
                    window.open(this.projectSelected.projectUrl, "_blank");
                }
            }
        },

        mounted() {
            var itemGrupoMenuDefault = this.userLoggedGetters.listGrupoMenu.filter(grp => grp.idGroupMenu == this.userLoggedGetters.idGroupMenuDefault)[0]

            if (itemGrupoMenuDefault == null || itemGrupoMenuDefault == undefined) {
                itemGrupoMenuDefault = this.userLoggedGetters.listGrupoMenu[0]
            }
            this.activeSubMenu(itemGrupoMenuDefault);
        }
    };
</script>

<style scoped>
    .hideInDesktop {
        display: none;
    }

    .hideInMobile {
        display: block;
        text-align: right;
    }

    .esconderMobile {
        display: none;
    }

    @media only screen and (max-width: 1263px) {
        .hideInDesktop {
            display: block;    
        }

        .esconderMobile {
            display: block;    
        }
    }

    @media only screen and (max-width: 600px) {

        .hideInMobile {
            display: none;
        }
    }
</style>